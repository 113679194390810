import React, { useState } from 'react';
import { Container, Grid, Box, Typography, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { useSelector, useDispatch } from 'react-redux';
import { changeData } from './securitySlice';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    editButton: {
        marginTop: '40px'
    },
  }));

const Security = () => {
    const classes = useStyles();
    
    const { user: {security} } = useSelector(state => state.account);  
    const { user: {bio: {login: {password}}} } = useSelector(state => state.account);  
    const { error, tSuccess } = useSelector(state => state.security);
    
    const dispatch = useDispatch(changeData);

    const [open, setOpen] = React.useState(false);

    const [ pin, setPin] = useState('');
    const [ pwd, setPwd] = useState('');
    const [ toggleInput, setToggleinput ] = useState(false)

    const handleClickOpen = (input) => {
        setOpen(true);
        if(input === "pwd"){
            setToggleinput(true);
        } else {
            setToggleinput(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };


    const changeInfo = (where, currentVal, input) => {
        
        let data = {where, value: input};
        if((currentVal !== input) && input !== "") {
            dispatch(changeData(data));
            setPin('');
            setPwd('');
        }
    }

    const changeInput = (e, setter) => {
        setter(e.target.value);
    }

    return (
        <section>
            <Box mt={4}>
                <Container maxWidth="sm">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color="primary"> Account Settings -> Security </Typography>
                            <Typography variant="body2" color="primary">
                                If you intend to change the data, type into the text field and click away
                            </Typography>
                        </Grid>

                        {<Grid item xs={12}>
                            <h4 style={{
                                color: tSuccess ? 'green' : 'red'
                                }}>
                                {tSuccess ? tSuccess: error}
                            </h4>
                        </Grid>}

                        <Grid item container xs={12} sm={6}>

                            <Grid item xs={7}>
                                <h5> Password </h5>
                                <p> Change your password ?</p>
                            </Grid>
                    
                            <Grid item xs={5}>
                                <Button 
                                    onClick={() => handleClickOpen("pwd")}
                                    className={classes.editButton} variant="outlined" color="primary">
                                    Edit
                                </Button>
                            </Grid>

                            <Grid item xs={7}>
                                <h5> Security Questions </h5>
                                <p> Change your Security Questions and Answers ?</p>
                            </Grid>

                            <Grid item xs={5}>
                                <Button disabled className={classes.editButton} variant="outlined" color="primary">
                                    Edit
                                </Button>
                            </Grid>
                        </Grid>


                        <Grid item container xs={12} sm={6}>
                            <Grid item container xs={12} sm={6}>

                                <Grid item xs={7}>
                                    <h5> Pin </h5>
                                    <p> Change your pin ?</p>
                                </Grid>

                                <Grid item xs={5}>
                                    <Button 
                                        onClick={() => handleClickOpen("pin")}
                                        className={classes.editButton} variant="outlined" color="primary">
                                        Edit
                                    </Button>
                                </Grid> 
                            </Grid>  
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Update your security information"}</DialogTitle>
                <DialogContent>
                        {toggleInput && <TextField
                            value={pwd}
                            onChange={(e) => changeInput(e, setPwd)}
                            type="password"
                            onBlur={() => changeInfo("bio.login.password", password, pwd)}
                            margin="normal"fullWidth id="pin" label="Enter your new password" 
                        />}

                        {!toggleInput && <TextField
                            value={pin}
                            onChange={(e) => changeInput(e, setPin)}
                            type="password"
                            onBlur={() => changeInfo("security.pin", security.pin, pin)}
                            margin="normal"fullWidth id="pin" label="Enter your new pin" 
                        />}


                    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Disagree
                </Button>
                <Button onClick={handleClose} color="primary">
                    Change
                </Button>
                </DialogActions>
            </Dialog>
        </section>
    )
}

export default Security