import React from 'react';
import { Hidden, Grid } from '@material-ui/core';
import SideMenu from '../sidemenu/sideMenu';
import LockIcon from '@material-ui/icons/Lock';

import { makeStyles } from '@material-ui/core/styles';
import styles from './Header.module.css';
import Navigation from '../navigation/Navigation';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  iPadrawer: {
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      flexShrink: 0,
      marginTop: '50px'
    },
  },
  drawerPaper: {
    width: '50%',
    marginTop: '50px'
  },
  mobileDrawer: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
  mobileDrawerPaper: {
    width: '100%'
  },
  centerItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  overRideBackDrop: {
    zIndex: 1000000
  },
  menuBtnImg: {
    height: 'auto',
    width: '100%',
    cursor: 'pointer'
  }
}));

const Header = () => {

    //const toggled = useSelector(selectToggle);
    //const dispatch = useDispatch();

    const classes = useStyles();

    return(
      <section>
        <Grid container>
          <Hidden smDown>
            <Grid container item className={styles.header}>
              <Grid item md={2} lg={1}>
              </Grid>

              <Grid item md={9} lg={10}>
                <Navigation />
              </Grid>

              <Grid item md={1}>
                <div className={styles.loginLink}>
                  <LockIcon fontSize="small" /> &nbsp; &nbsp;
                    <nav> <NavLink activeClassName={styles.isActive} to='/login'>Log in</NavLink> </nav>
                </div>
              </Grid>
            </Grid>

          </Hidden>

          <Hidden mdUp>
            <Grid item xs={2} sm={1} className={classes.centerItem}>
              <NavLink to="/login">
                <img src='https://i.ibb.co/86H779s/login.jpg' alt="Login" style={{height: 'auto', width: '100%'}} />
              </NavLink>
            </Grid>


            <Grid item xs={8} sm={10} className={classes.centerItem} >
              <img src='https://i.ibb.co/dp157Bh/rbs-logo.png' alt="Logo" height='25px' />
            </Grid>


            <Grid item xs={2} sm={1} className={`${classes.centerItem} ${classes.overRideBackDrop}`}>
              <SideMenu />
            </Grid>
          </Hidden>

        </Grid>

      </section>
    )
}

export default Header;
