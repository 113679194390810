import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Nomatch from '../../components/Nomatch';
import {routingData} from '../../data';
import LowerNav from '../../features/lowerNav/LowerNav';
import BusinessHome from '../business-home/BusinessHome';


const Business = () => {
    document.title="R.B.S Business Securities Options"

    return(
      <section>

        <LowerNav routingData={routingData[2]} />

        <Switch>
          <Route exact path="/h/business">
              <BusinessHome />
          </Route>
          {
            routingData[2].map((path, index) => (
              <Route key={index} path={path.to} component={path.component} />
            ))
          }
          <Route component={Nomatch} />
        </Switch>
      </section>
    )
}

export default Business;
