import React from 'react';
import { Container, Grid, Box } from '@material-ui/core';
import styles from './ImageText.module.css';


const ImageText = ({ PageText, image, floatImageRight }) => {
  return(
    <section>
      <Container maxWidth="md">
        <Grid container>
          {!floatImageRight &&
            <Grid item xs={12} sm={6}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box mt={4} mr={4}>
                  <img alt="img of text" src={image} className={styles.fluidImage} />
                </Box>
              </div>
            </Grid>
          }

          <Grid item xs={12} sm={6} className={styles.imageText}>
            <PageText />
          </Grid>

          {floatImageRight &&
            <Grid item xs={12} sm={6}>
              <Box mt={4} pb={4}>
                <img alt="img of text" src={image} className={styles.fluidImage} />
              </Box>
            </Grid>
          }
        </Grid>

      </Container>
    </section>
  )
}

export default ImageText;
