import React from 'react';
import SiteMap from '../../features/site-map/SiteMap';
import PageTop from '../../features/page-top/PageTop';
import { switchData } from '../../data';
import ThreeImageCard from '../../features/three-image-card/ThreeImageCard';
import ImageText from '../../features/image-text/ImageText';
import { manPressingPhoneImg } from '../../images';

const SupportImageText = () => {
  return (
    <section style={{color: 'white'}}>
      <h1> PPI claims </h1>

      <p>
        Have a question about Payment Protectoion Insurance (PPI)?
      </p>


      <br /><br />

      <br />
    </section>
  )
}


const SupportCentre = () => {
  return (
    <section>
      <SiteMap sliceIndex={2} />
      <br />

      <PageTop
        header="If you need a helping hand, we're here"
        buttonText="Explore all Current Accounts"
        bgImage = "support"
        displayBtn = {false}
       />

      <ThreeImageCard cardData={switchData[2]} />

      <section style={{background: '#002D64'}}>

        <ImageText
          PageText={SupportImageText}
          image={manPressingPhoneImg}
          floatImageRight={true}
         />
      </section>
      <br /><br />

      <ThreeImageCard cardData={switchData[4]} />




    </section>
  )
}

export default SupportCentre;
