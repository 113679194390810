import { createSlice } from '@reduxjs/toolkit';

const sideMenuSlice = createSlice({
    name: 'sideMenu',
    initialState: {
        toggled: false
    },
    reducers: {
        toggleMenu: state => {
            state.toggled = !state.toggled;
        }
    }
})

export const { toggleMenu } = sideMenuSlice.actions;

export const selectToggle = state => state.sideMenu.toggled;

export default sideMenuSlice.reducer;