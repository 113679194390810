import React, { useState } from 'react';
import { Container, Grid, Box, Typography, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import styles from './profile.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { changeData, uploadImage } from './profileSlice';

import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import BackDrop from '../../../../features/backdrop/BackDrop';

const Profile = () => {
    const { user: {bio, account} } = useSelector(state => state.account); 
    const { loading } = useSelector(state => state.profile); 

    //const { error } = useSelector(state => state.profile);

    const dispatch = useDispatch(changeData);

    const [ email, setEmail] = useState('');
    const [ number, setNumber] = useState('');
    // const [ addresskinaddress, setAddress] = useState('');
    // const [ kincountry, setKinCountry] = useState('');

    //https://dev.to/itnext/how-to-do-image-upload-with-firebase-in-react-cpj
    //const allInputs = {imgUrl: ''}
    const [imageAsFile, setImageAsFile] = useState('')
    //const [imageAsUrl, setImageAsUrl] = useState(allInputs)

    const changeInfo = (where, currentVal, input) => {
        let data = {where, value: input};
        if((currentVal !== input) && input !== "") {
            dispatch(changeData(data));
        }
    }

    const changeInput = (e, setter) => {
        setter(e.target.value);
    }

    //code gotten from 
    //https://dev.to/itnext/how-to-do-image-upload-with-firebase-in-react-cpj
    const handleImageAsFile = (e) => {
        const image = e.target.files[0];
        //console.log(image);
        setImageAsFile(image);
    }

    const handleFireBaseUpload = e => {
        e.preventDefault();
        if(imageAsFile === '' ) {
            console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
        } else {
            dispatch(uploadImage(
                imageAsFile, bio.login.username
                )
            )
        }
        
    }

    return (

        <section>
            <Box mt={4}>
                <Container maxWidth="md">
                    <form>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary"> Account Settings -> Profile</Typography>
                                <Typography variant="body2" color="primary">
                                    If you intend to change the data, type into the text field and click away
                                </Typography>
                            </Grid>

                            <Grid item container xs={12} sm={7}>
                                <Grid item xs={4}>
                                    <p>
                                        <strong> Name: </strong>
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p>
                                        {bio.name}
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p className={styles.inputP}>
                                        <strong> Email: </strong>
                                    </p>
                                    
                                </Grid>

                                <Grid item xs={8}>
                                    <div>
                                        <TextField
                                            value={email}
                                            onChange={(e) => changeInput(e, setEmail, "bio.email")}
                                            onBlur={() => changeInfo("bio.email", bio.email, email)}
                                            margin="normal"fullWidth id="email" label={bio.email} 
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <p className={styles.inputP}>
                                        <strong> Phone: </strong>
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <div>
                                        <TextField
                                            value={number}
                                            onChange={(e) => changeInput(e, setNumber, "bio.phonenumber")}
                                            onBlur={() => changeInfo("bio.phonenumber", bio.phonenumber, number)}
                                            margin="normal"fullWidth id="number" label={bio.phonenumber} 
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <p>
                                        <strong> Address: </strong>
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p> {bio.address.street} {bio.address.city}, {bio.address.state} </p>
                                </Grid>
                                
                                <Grid item xs={4}>
                                    <p>
                                        <strong> Country: </strong>
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p> {bio.address.country} </p>
                                </Grid>


                                
                            </Grid>

                            <Grid item container xs={12} sm={5}>
                                <Grid item xs={4}>
                                    <p>
                                        <strong> DOB: </strong>
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p>
                                    {bio.dob.slice(0,10)}
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p className={styles.inputP}>
                                        <strong> Account Officer: </strong>
                                    </p>
                                </Grid>

                                <Grid item xs={8}>
                                    <p className={styles.adjustMargin}>
                                        {account.accountofficer}
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p className={styles.inputP}>
                                        <strong> A.O. code: </strong>
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p className={styles.adjustMargin}>
                                        {account.accountofficercode}
                                    </p>
                                </Grid>

                                <Grid item xs={4}>
                                    <p>
                                        <strong> A.O. email: </strong>
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <p> {account.accountofficeremail} </p>
                                </Grid>
                                
                                <Grid item xs={4}>
                                    <p>
                                        <strong> Upoad Picture: </strong>
                                    </p>
                                </Grid>
                                <Grid item xs={8}>
                                    <div>
                                        <input onChange={handleImageAsFile} accept="image/*" id="icon-button-file" type="file" />
                                        <label htmlFor="icon-button-file">
                                            <IconButton color="primary" aria-label="upload picture" component="span">
                                            <PhotoCamera />
                                            </IconButton>
                                        </label>
                                        <Button onClick={handleFireBaseUpload} variant="contained" color="primary" component="span">
                                            Upload
                                        </Button>
                                    </div>
                                </Grid>


                                
                            </Grid>
                       
                       
                        </Grid>
                    </form>
                </Container>  
            </Box>

            <BackDrop loading={loading} />

        </section>
    )
}

export default Profile