import React from 'react';
import styles from './iconSidebar.module.css';
import dgs from '../../dashGlobal.module.css';
import { NavLink } from 'react-router-dom';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { logOut } from '../../dash-comp/account/accountSlice';
import Popover from '../popover/Popover';

import { useSelector } from 'react-redux';


const IconSidebar = () => {
  const { user } = useSelector(state => state.account);
  
  const menuIcons = [
    {
      icon: <AccountBalanceIcon className={dgs.iconEl} />,
      iconText: 'Account',
      url: '/dashboard/account'
    },
    {
      icon: <LocalAtmIcon className={dgs.iconEl} />,
      iconText: 'Transfer',
      url: '/dashboard/transfer'
    },
    {
      icon: <SupervisorAccountIcon className={dgs.iconEl} />,
      iconText: 'Next of Kin',
      url: '/dashboard/kin'
    },
    {
      icon: <SettingsIcon className={dgs.iconEl} />,
      iconText: 'Profile',
      url: '/dashboard/profile'
    },
    {
      icon: <LocalActivityIcon className={dgs.iconEl} />,
      iconText: 'History',
      url: '/dashboard/history'
    },
    {
      icon: <InvertColorsIcon className={dgs.iconEl} />,
      iconText: 'Security',
      url: '/dashboard/Security'
    }

  ]
  return(
    <section>
      <div className={styles.iconOnly}>
        <img alt="profile-icon"
          src={
            user ? user.bio.image :
            "http://light.pinsupreme.com/img/avatar1.jpg"
          }
          style={{height: '50px', borderRadius: '50%'}}
          />
          {menuIcons.map((icon, index) => (
            <div key={index} className={styles.menuLink}>
              <NavLink className={styles.iconLink} activeClassName={styles.dashActive} to={icon.url}>
                <span className={styles.mIcon}> {icon.icon} </span>
              </NavLink>
            </div>
          ))}
          <div className={styles.logout}>
          <span> 
            <Popover yes={logOut} icon={<ExitToAppIcon />} /> </span>
          </div>
      </div>
    </section>
  )
}

export default IconSidebar
