import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './navigation.module.css';

const Navigation = () => {
  return (
      <nav>
        <NavLink activeClassName={styles.isActive} to='/h/personal'>Personal</NavLink>
        <NavLink activeClassName={styles.isActive} to='/h/premier'>Premier</NavLink>
        <NavLink activeClassName={styles.isActive} to='/h/business'>Business</NavLink>
        <NavLink activeClassName={styles.isActive} to='/h/contact'>Contact</NavLink>
      </nav>
  )
}

export default Navigation;
