import React from 'react';
import styles from './ListWithCheck.module.css';
import InfoIcon from '@material-ui/icons/Info';

const ListWithCheck = ({ listData }) => {
  return (
    <section className={styles.listData}>
      <h2> {listData.header} </h2>
      <p> {listData.text} </p>

      <div className={styles.baList}>
        <ul>
          {listData.listItems.map((item, index) => (
            <li key={index}> {item} </li>
          ))}
        </ul>

      </div>

      { listData.info && <p className={styles.centerItem}>
        <InfoIcon className={styles.info} /> &nbsp; &nbsp;
          <span> {listData.info} </span>
      </p>}

      {listData.btnText && <button className={styles.viewAccountBtn}>
        <a href={listData.btnLink} color="white"> {listData.btnText} </a>
      </button>}
    </section>
  )
}

export default ListWithCheck;
