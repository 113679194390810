import React from 'react';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';
import styles from './pageTopSolid.module.css';

const PageTopSolid = ({ header, text}) => {
  return (

    <section className={styles.cont}>
      <ContainerMaxWidth>
        <h1> {header} </h1>

        <h4> {text} </h4>
      </ContainerMaxWidth>
    </section>
  )
}

export default PageTopSolid;
