import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './account.module.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { Grid, Container, Hidden } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import History from '../history/History';

const Account = () => {
  const [value, onChange] = useState(new Date());

  const { user } = useSelector(state => state.account);

  //console.log(user);


  return (
    <section>
      <Container maxWidth="lg">
        <hr />
        <br />
        <Grid container>
          <Grid item xs={12} sm={12} md={8}>
            <Grid item container spacing={4}>
              <Grid item xs={6} sm={4}>
                <Card elevation={6}>
                  <CardContent className={styles.cardContent}>
                    <h4> Total Balance </h4>
                    <h1>
                      {user.account.currency}
                      {
                        (!user.account.accountbalance) ?
                        "0.00" : user.account.accountbalance.toLocaleString()
                      }

                      
                    </h1>
                    <a href="/dashboard/history">
                      View History
                    </a>
                  </CardContent>
                </Card>
              </Grid>


              <Grid item xs={6} sm={4}>
                <Card elevation={6}>
                  <CardContent className={styles.cardContent}>
                    <h4> Credit Balance </h4>
                    <h1>
                      {user.account.currency}
                      {
                        (!user.account.creditbalance) ?
                        "0.00" : user.account.creditbalance.toLocaleString()
                      }
                    </h1>
                    <a href="/dashboard/history">
                      View History
                    </a>
                  </CardContent>
                </Card>
              </Grid>

              <Hidden xsDown>
                <Grid item xs={4} sm={4}>
                  <Card elevation={6}>
                    <CardContent className={styles.cardContent}>
                      <h4> Referral </h4>
                      <h1> {user.account.currency}0.00 </h1>
                      <br />
                    </CardContent>
                  </Card>
                </Grid>
              </Hidden>

            </Grid>


          </Grid>


          <Grid item xs={12} sm={12} md={4}>
            <div className={styles.refer}>
              <h4> Refer Friends. Get Rewarded </h4>
              <p>
                You can earn: 15,000 Membership Rewards points for each
                approved referral – up to 55,000 Membership Rewards points
                per calendar year.
              </p>
            </div>
          </Grid>
        </Grid>
        <br /><br /> <br />
        <Grid container spacing={3}>

          <Grid item xs={12} sm={6} md={4}>
            <Card elevation={6}>
              <CardContent>
                <h4> Do you want to send money to a friend or family </h4>
                <h4> OR do you want to pay for purchased stuffs, click Below  </h4>
              </CardContent>

              <CardActions>
                <NavLink to="/dashboard/transfer">Make a Transfer </NavLink>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card elevation={6}>
              <CardContent className={styles.cardContent}>
                <h3> YOUR AWARENESS </h3>
                <p>You can chat us whenever you need anything, we'll be here 24/7 </p>

                <p> You have also added no payee this month </p>

                <p> You need to upload a picture to get started </p>

                <p> We are here to serve you, don't forget to chat us up if you need anything.</p>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <div>
              <Calendar
                onChange={onChange}
                value={value}
              />
            </div>
          </Grid>
        </Grid>

        <div style={{height: '400px', overflow: 'hidden'}}>
          <History />
        </div>

        </Container>
    </section>
  )
}

export default Account;
