import React from 'react';
import Header from '../../features/header/Header';
import Body from './Body';
import Footer from '../../features/footer/Footer';

const Main = () => {
    return(
        <div>
            <Header />

            <Body />

            <Footer />
        </div>
    )
}

export default Main;
