import React from 'react';
import SiteMap from '../../features/site-map/SiteMap';
import PageTop from '../../features/page-top/PageTop';
import ThreeImageCard from '../../features/three-image-card/ThreeImageCard';
import { switchData } from '../../data';
import ImageText from '../../features/image-text/ImageText';
import btnStyle from '../../features/image-text/ImageText.module.css';
import { greenToolBoxImg, laptopImage, stethoscopeImg } from '../../images';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';

const PST1 = () => {
  return(
    <div>
      <h4 style={{fontSize: '2.4em'}}>
        Make the very most of your account.
      </h4>

      <p> View and manage the benefits of your Premier account </p>

      <button className={btnStyle.signupBtn}> <strong>Manage your account</strong> </button>
    </div>
  )
}

const PST2 = () => {
  return(
    <div>
      <h4 style={{fontSize: '2.4em'}}>
        How to use your Membership Benefits
      </h4>

      <p>
        If you’re a Silver, Platinum, or Black account customer, you can access your
        lifestyle, travel and insurance benefits online.
      </p>
      <br />
      <button className={btnStyle.signupBtn}>
        <strong>Log into Membership <br />Services</strong>
      </button>
      <br /><br />
    </div>
  )
}

const PST3 = () => {
  return(
    <div>
      <h4 style={{fontSize: '2.4em'}}>
        Do more with Premier
      </h4>

      <p>
        A Financial Health Check will consider all areas of your finances.
        Whether you want to take stock or you're making plans for the future, we can help.
      </p>
      <br />
      <button className={btnStyle.signupBtn}>
        <strong>See how we can help</strong>
      </button>
      <br /><br />
    </div>
  )
}



const PremierSupport = () => {
  return (
    <section>
      <SiteMap sliceIndex={2} />
      <br />

      <PageTop
        header="We're here to help, round the clock"
        buttonText="What is Premier Banking?"
        buttonLink="/current-account"
        bgImage = "premierSupport"
        displayBtn = {false}
       />

     <ThreeImageCard cardData={switchData[9]} />

      <ImageText
        PageText={PST1}
        image={greenToolBoxImg}
       />

     <section style={{background: '#280020', color: 'white'}}>
       <ContainerMaxWidth>
         <ImageText
           PageText={PST2}
           image={laptopImage}
           floatImageRight={true}
          />
       </ContainerMaxWidth>
     </section>


      <ImageText
        PageText={PST3}
        image={stethoscopeImg}
       />

     <ThreeImageCard cardData={switchData[10]} />

    </section>
  )
}

export default PremierSupport;
