import React from 'react';
import { Grid, Container } from '@material-ui/core';
import styles from './threeImageCard.module.css';


const ThreeImageCard = ({cardData }) => {
  return (
    <section className={styles.imageCards}>
      <Container maxWidth="md">

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <h1> Switch to Royal Bank of Scotland </h1>
            </Grid>

            {
              cardData.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Grid container item xs={12}>
                    {card.image &&
                      <img src={card.image} alt={card.caption} className={styles.fluidImage} />
                    }

                    <h2> {card.caption}</h2>

                    {card.switchText &&<p> {card.switchText} </p>}

                    {card.switchLink && <span>
                      <a href={card.switchLink}>{card.switchLinkText}</a>
                    </span>}
                  </Grid>
                </Grid>
              ))
            }
          </Grid>

        <br /><br />
      </Container>
    </section>

  )
}

export default ThreeImageCard;
