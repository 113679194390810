import { createSlice } from '@reduxjs/toolkit';
import api from '../../../../features/api/axios';


const accountSlice = createSlice({
  name: 'accountRd',
  initialState: {
    user: {},
    error: '',
    loading: true
  },
  reducers: {
    fetchProfile: (state, action) => {
      state.loading = true
    },
    profileSuccess: (state, action) => {
      return {...state, error: '', user: action.payload, loading: false}
    },
    profileError: (state, action) => {
      return {...state, error: action.payload}
    }
  }
})

export const { fetchProfile, profileSuccess, profileError } = accountSlice.actions;

export default accountSlice.reducer;

//Thunks

export const getProfile = () => async dispatch => {
  try {
    dispatch(fetchProfile);
    //First grab the username from localStorage if it's saved
    const userInfo = JSON.parse(localStorage.getItem('user'))
    const uname = userInfo.uname;

    if(uname){
      const response = await api.get(`/profile/${uname}`);
      const user = response.data[0];
      //console.log(user);
      dispatch(profileSuccess(user));
    } else {
      console.log("You're not logged in.");
      dispatch(profileError('Please login first'));
    }

  } catch (err) {
    dispatch(profileError('Please login first'));
  }
}

export const logOut = () => {
  localStorage.clear();
  window.location.replace("/");
}