import firebase from 'firebase/app';
import 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
apiKey: "AIzaSyBmRiPvND5P57xUGZQqFiUbTL319gjYoLM",
    authDomain: "bk-images.firebaseapp.com",
    databaseURL: "https://bk-images.firebaseio.com",
    projectId: "bk-images",
    storageBucket: "bk-images.appspot.com",
    messagingSenderId: "933556197700",
    appId: "1:933556197700:web:a6fb0bc6bc7a7927445f11",
    measurementId: "G-EKST43E80J"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage()

//firebase.analytics();

export {
    storage, firebase as default
}