import React from 'react';
import { Grid, Container } from '@material-ui/core';

const SiteMap = ({ sliceIndex}) => {
  // Grab the window's object and extract the path from it
  let path = window.location.hash.split('/').slice(sliceIndex,);

  return (
    <section>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item>
            <br />
            {
              path.map((currentPath, index) => (
                <span key={currentPath} style={{fontSize: '1.1em', color: '#1D7B8A'}}>
                  <span style={{paddingRight: '6px'}}>
                    {currentPath.replace(currentPath[0], currentPath[0].toUpperCase())}
                  </span>
                  <span style={{paddingRight: '6px'}}>
                    {index !== (path.length-1) ? '>' : null}
                  </span>
                </span>
              ))
            }
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default SiteMap;
