import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import styles from './regForm.module.css';
import { Validator } from '../../features/helpers/validator';
import { setError, clearError } from './regformSlice';
import { countryList } from '../../data';


const Kin = ({state, dispatch}) => {

    const [kinName, setKinName] = useState('');
    const [kinNumber, setKinNumber] = useState('');
    const [kinAddress, setKinAddress] = useState('');
    const [kinCity, setKinCity] = useState('');
    const [kinState, setKinState] = useState('');
    const [kinCountry, setKinCountry] = useState('');
    const [kinLanguage, setKinLanguage] = useState('');

    //console.log(state);
    
    const inputChange = (e, setter) => {
        setter(e.target.value);
    }
    
    const validate = (e, input, inputString) => {
        const result = Validator(e, input, inputString);
        
        if(result.error){
            dispatch(setError({
                input: result.input,
                err: result.errorMessage
            }));
        } else {
            dispatch(clearError({
                input: result.input,
                val: result.inputValue
            }))
        }
    }

    return (
        <section className={styles.cover}>
            <div className={styles.headerInfo}>
                <Typography variant="h6" color="primary"> Next of Kin Details</Typography>
                <Typography variant="body2" color="primary">
                    In case of your unavailability, please fill out your next of kin.
                </Typography>
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="kinname"> Kin Name </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="kinname"
                    onChange={(e) => inputChange(e, setKinName)}
                    required
                    onBlur={(e) => validate(e, kinName, "kinname")}
                    error={state.kinname !== ''}
                    helperText={
                        (state.kinname) ? state.kinname : ""
                    }
                />
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="kinnumber"> Kin Number  </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="kinnumber"
                    type="number"
                    onChange={(e) => inputChange(e, setKinNumber)}
                    required
                    onBlur={(e) => validate(e, kinNumber, "kinnumber")}
                    error={state.kinnumber !== ''}
                    helperText={
                        (state.kinnumber) ? state.kinnumber : ""
                    }
                />
            </div>
                
            <div className={styles.formGroup}>
                <label htmlFor="kinaddress"> Kin Address </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="kinaddress"
                    onChange={(e) => inputChange(e, setKinAddress)}
                    required
                    onBlur={(e) => validate(e, kinAddress, "kinaddress")}
                    error={state.kinaddress !== ''}
                    helperText={
                        (state.kinaddress) ? state.kinaddress : ""
                    }
                />
            </div>

            <Grid container>
                <Grid item container xs={12} sm={6} spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <div className={styles.formGroup}>
                            <label htmlFor="kincity"> Kin City </label>
                            <TextField 
                                size="small" 
                                variant="outlined"
                                id="kincity"
                                onChange={(e) => inputChange(e, setKinCity)}
                                required
                                onBlur={(e) => validate(e, kinCity, "kincity")}
                                error={state.kincity !== ''}
                                helperText={
                                    (state.kincity) ? state.kincity : ""
                                }
                            />
                        </div>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <div className={styles.formGroup}>
                            <label htmlFor="kinstate"> Kin State </label>
                            <TextField 
                                size="small" 
                                variant="outlined"
                                id="kinstate"
                                onChange={(e) => inputChange(e, setKinState)}
                                required
                                onBlur={(e) => validate(e, kinState, "kinstate")}
                                error={state.kinstate !== ''}
                                helperText={
                                    (state.kinstate) ? state.kinstate : ""
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <div className={styles.formGroup}>
                <label htmlFor="kincountry"> Kin Country </label>                                
                <TextField
                    id="kin_country"
                    name="kin_country"
                    select
                    value={kinCountry}
                    onChange={(e) => inputChange(e, setKinCountry)}
                    onBlur={(e) => validate(e, kinCountry, "kincountry")}
                    error={state.kincountry !== ''}
                    helperText={
                        (state.kincountry) ? state.kincountry : ""
                    }
                    >                                
                    {countryList.map((cnt) => (
                        <MenuItem key={cnt.name} value={cnt.name}>
                            {cnt.name} ({cnt.code})
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="kinlanguage"> Kin Language </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="kinlanguage"
                    onChange={(e) => inputChange(e, setKinLanguage)}
                    required
                    onBlur={(e) => validate(e, kinLanguage, "kinlanguage")}
                    error={state.kinlanguage !== ''}
                    helperText={
                        (state.kinlanguage) ? state.kinlanguage : ""
                    }
                />
            </div>


        </section>
    )
}

export default Kin;