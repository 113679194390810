import { configureStore } from '@reduxjs/toolkit';
import { getDefaultMiddleware } from '@reduxjs/toolkit'
import sideMenuReducer from '../features/sidemenu/sideMenuSlice';
import loginReducer from '../components/login/loginSlice';
import dashboardReducer from '../components/dashboard/dashboardSlice';
import transferReducer from '../components/dashboard/dash-comp/transfer/transferSlice';
import kinReducer from '../components/dashboard/dash-comp/kin/kinSlice';
import profileReducer from '../components/dashboard/dash-comp/profile/profileSlice';
import securityReducer from '../components/dashboard/dash-comp/security/securitySlice';
import regformReducer from '../components/reg-form/regformSlice';

export default configureStore({
  reducer: {
    sideMenu: sideMenuReducer,
    login: loginReducer,
    account: dashboardReducer,
    transfer: transferReducer,
    kin: kinReducer,
    profile: profileReducer,
    security: securityReducer,
    regForm: regformReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: [
        'regformRd/clearError', 'regformRd/setError', 
        'regformRd/regError', 'regformRd/toggleLoading',
        'regformRd/regSuccess'
      ]
    }
  })
});
