import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Personal from '../personal/Personal';
import Premier from '../premier/Premier';
import Business from '../business/Business';
import Contact from '../contact/Contact';
import Nomatch from '../Nomatch';

const Body = () => {
    return(
        <Switch>
            <Route path='/h/personal' component={Personal} />
            <Route path='/h/premier' component={Premier} />
            <Route path='/h/business' component={Business} />
            <Route path='/h/contact' component={Contact} />
            <Route component={Nomatch} />
        </Switch>
    )
}

export default Body;
