import React, { useState } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { whiteLogo } from '../../../../images';
import styles from './dashHeader.module.css';
import dgs from '../../dashGlobal.module.css';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuIcon from '@material-ui/icons/Menu';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Drawer from '@material-ui/core/Drawer';
import SideMenu from '../side-menu/SideMenu';

import { NavLink } from 'react-router-dom';

import { logOut } from '../../dash-comp/account/accountSlice';
import Popover from '../popover/Popover';

import { useSelector } from 'react-redux';

const DashHeader = () => {
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [toggled, setToggled] = useState(false);


  const { user } = useSelector(state => state.account);

  //console.log(user);


  const handleClick = (e, setAnchor) => {
    setAnchor(e.currentTarget);
  }

  const handleClose = (setAnchor) => {
    setAnchor(null);
  }

  const toggleDrawer = (open) => {
    setToggled(!toggled);
  }

  return (
    <section>
      <Grid container className={styles.headerCont}>
        <Grid item xs={6} sm={3} lg={2}>
          <NavLink to="/">
            <img alt="logo" src={whiteLogo} />
          </NavLink>
        </Grid>

        <Hidden xsDown>
          <Grid item sm={7} lg={9}>
          <div  align="right">
            <marquee  direction="left" >
              <h4>
                Royal Bank of scotland,
                Edit, view and transfer money from your 
                account securely.
              </h4>
            </marquee>
          </div>
          </Grid>
        </Hidden>

        <Grid item xs={6} sm={2} lg={1}>
          <div className={dgs.centralizeItems}>
            <span>
            <Button aria-controls="simple-menu"
              aria-haspopup="true" onClick={(e) => handleClick(e, setAnchorElSettings)}>
                <SettingsIcon style={{color: 'white'}} />
              </Button>

              <Menu
                id="simple-menu1"
                anchorEl={anchorElSettings}
                keepMounted
                open={Boolean(anchorElSettings)}
                onClose={(e) => handleClose(setAnchorElSettings)}
                elevation={2}
              >
                <MenuItem onClick={(e) => handleClose(setAnchorElSettings)}>
                  <NavLink className={styles.link} to="/dashboard/account"> Account </NavLink>
                </MenuItem>
                <MenuItem onClick={(e) => handleClose(setAnchorElSettings)}>
                <NavLink className={styles.link} to="/dashboard/security"> Security </NavLink>
                </MenuItem>
                <MenuItem onClick={(e) => handleClose(setAnchorElSettings)}>
                <NavLink className={styles.link} to="/dashboard/kin"> Kin </NavLink>
                </MenuItem>
              </Menu>
            </span>

            <span>
              <Button aria-controls="simple-menu"
                aria-haspopup="true" onClick={(e) => handleClick(e, setAnchorElMenu)}>
                <img alt="profile-icon"
                  src={
                    user ? user.bio.image :
                    "http://light.pinsupreme.com/img/avatar1.jpg"
                  }
                  style={{height: '70px', width: '70px', borderRadius: '20%'}}
                  />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorElMenu}
                keepMounted
                open={Boolean(anchorElMenu)}
                onClose={(e) => handleClose(setAnchorElMenu)}
                elevation={2}
              >
                <MenuItem onClick={(e) => handleClose(setAnchorElMenu)}>
                  <NavLink className={styles.link} to="/dashboard/profile"> Profile </NavLink>
                </MenuItem>
                <MenuItem onClick={(e) => handleClose(setAnchorElMenu)}>
                  <NavLink className={styles.link} to="/dashboard/history"> History </NavLink>
                </MenuItem>
                <MenuItem onClick={(e) => handleClose(setAnchorElMenu)}>
                  <NavLink className={styles.link} to="/dashboard/transfer"> Transfer </NavLink>
                </MenuItem>
                <MenuItem>
                  <Popover yes={logOut} />
                </MenuItem>
              </Menu>
            </span>

            <Hidden smUp>
              <span>
                <Button onClick={toggleDrawer}>
                  <MenuIcon style={{color: 'white'}} />
                </Button>
              </span>
            </Hidden>

          </div>
        </Grid>
      </Grid>



      <Drawer anchor="right" open={toggled} onClose={toggleDrawer}>
        <SideMenu toggledrawer={toggleDrawer} />
      </Drawer>
    </section>
  )
}

export default DashHeader;
