import { createSlice } from '@reduxjs/toolkit';
import api from '../../features/api/axios';

const loginSlice = createSlice({
  name: 'loginRed',
  initialState: {
    error: '',
    loading: false,
    user: {}
  },
  reducers: {
    fetchUser: state => {
      return {...state, loading: true, error: null}
    },
    loginSuccess: (state, action) => {
      return {...state, loading: false, user: action.payload}
    },
    loginError: (state, action) => {
      return {...state, error: action.payload , loading: false }
    }
  }
})

export const { fetchUser, loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;

//async login function
export const login = (loginData) => {
  return async dispatch => {

    dispatch(fetchUser());
    try {
       const response = await api.post(
         "/login", {loginData}, {raxConfig: { retry: 3, retryDelay: 4000}}
       );

       const token = await response.data.token;
       const user = await response.data.client;

       if(response.status !== 202){
         dispatch(loginError(response.data.message))
       } else {

         dispatch(loginSuccess(user));

         //create an object to hold the token and username
         //the username is saved so it can be used to make a get request
         //to profile api
         let userInfo = {
           token,
           uname: loginData.one
         }
         //save token for easy retrieval
         localStorage.setItem('user', JSON.stringify(userInfo));




         window.location = "../#/dashboard"
       }
    } catch (err) {
      console.log(err);
      dispatch(loginError("Can't reach the server, please try again later!!!"))
    }
  }
}
