import { createSlice } from '@reduxjs/toolkit';
import api from '../../../../features/api/axios';
import { storage } from '../../../../app/firebase';

const profileSlice = createSlice({
    name: 'profileRd',
    initialState: {
        error: '',
        email: '',
        number: '',
        address: '',
        language: '',
        tSuccess: '',
        loading: false
    },
    reducers: {
        setError: (state, action) => {
            state.error = action.payload
        },
        doneEditing: (state, action) => {
            state.tSuccess = action.payload;
            state.error = "";
        },
        toggleLoading: state => {
            state.loading = !state.loading;
        },
    }
})

export const { doneEditing, setError, toggleLoading } = profileSlice.actions;

export default profileSlice.reducer;

export const changeData = (data) => async (dispatch) => {
    try {
        const response = await api.put("/profile", data);
        if(response.data.success){
            dispatch(doneEditing(response.data.success));
            window.location.reload();
        } else {
            dispatch(setError(response.data.error));
        }
    } catch (err) {
        dispatch(setError("An error occured, please try again!!!"));
    }
}

export const uploadImage = (imageAsFile, username) => async dispatch => {
    dispatch(toggleLoading());
    
    //Get the image extension
    let imageExt = imageAsFile.name.split('.').pop();

    //create file name from the user's username
    // and the file extension uploaded
    let fileName = `${username}.${imageExt}`
    
    try {
        const uploadTask = storage.ref(`/rbs/${fileName}`)
        .put(imageAsFile)

        uploadTask.on('state_changed',
            (snapShot) => {
                //takes a snap shot of the process as it's happening
                //console.log(snapShot);
            }, (err) => {

                //console.log("hello, are you here")
                throw new Error('Upload was unsuccessful!')
            }, () => {
                storage.ref('rbs').child(fileName).getDownloadURL()
                    .then(fireBaseUrl => {
                        api.post("/saveimage",
                            {
                                img: fireBaseUrl,
                                one: username
                            })
                            .then(response => {
                                dispatch(doneEditing(response.data.success));
                                window.location.reload();
                            }, error => {
                                throw new Error('Refresh to verify your upload')
                            })      
                    })
            }
        )

    } catch(err) {
        dispatch(setError(err.message));
    }
}