import React from 'react';
import SiteMap from '../../features/site-map/SiteMap';
import PageTopSolid from '../../features/page-top-solid/PageTopSolid';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';
import { Grid } from '@material-ui/core';
import { listWithTopBorder } from '../../data';
import ListComponent from '../../features/list-component/ListComponent';
import ImageText from '../../features/image-text/ImageText';
import { ncscImg } from '../../images';

const BST1 = () => {
  return(
    <section>
      <h1 style={{marginTop: '-10px', fontSize: '2.5em', color: '#002D64'}}>
        Working with the NCSC to help SMEs
      </h1>

      <p style={{marginTop: '-50px'}}>
        We’re collaborating with the National Cyber Security Centre (NCSC) to bring
        you the latest cyber security advice and support.
      </p>

      <p>
        The NCSC guide for small and medium sized businesses is an excellent place
        to start. It provides simple steps you can take to improve your security.
      </p>

      <p> Visit the NCSC business guide </p>

      <p>
        You can also test your business’s readiness and practice your response with
        the NCSC’s online tool.
      </p>
    </section>
  )
}

const BusinessSecurity = () => {
  return (
    <section>
      <SiteMap sliceIndex={2} />
      <br />

      <PageTopSolid
        header="Fraud and Security"
        text="guidance to help you beat the criminals"
        />

      <ContainerMaxWidth>
        <Grid container spacing={4}>
          <Grid item>
            <p> Learn how to protect your business, suppliers and customers from loss. </p>
            <p>
              This page provides information on how to report fraud and the support
              we offer you to stay safe.
            </p>
          </Grid>

          <Grid item xs={12}>
            <h1 style={{fontSize: '3.2em', color: '#002D64', textAlign: 'center'}}>
              Scams to watch out for
            </h1>
          </Grid>
          {
            listWithTopBorder[4].map((ebf, index) => (
              <Grid item xs={12} sm={6} md={4} key={ebf.heading}>
                <ListComponent listData={listWithTopBorder[4][index]} />
              </Grid>
            ))
          }
        </Grid>
      </ContainerMaxWidth>

      <section style={{background: '#F5F5F5'}}>
        <ImageText
          PageText={BST1}
          image={ncscImg}
          floatImageRight={true}
         />
      </section>


    </section>

  )
}

export default BusinessSecurity;
