import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  choice: {
      paddingLeft: '13px',
      paddingBottom: '5px'
  }
}));

export default function SimplePopover({yes, icon}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <span onClick={handleClick}>
        {icon ? icon : 'Logout'}
      </span>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography variant="body2" className={classes.typography}>
            Are you sure you want to log out ?
        </Typography>
        <div className={classes.choice}>
            <button onClick={handleClose}> No </button> &nbsp;
            <button onClick={yes}>Yes</button> 
        </div>
      </Popover>
    </div>
  );
}