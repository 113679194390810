import React, {useEffect, useRef} from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const SnackBar = (state) => {
    const success = state.state.appSuccess;
    const btnRef = useRef();

    useEffect(() => {
        if(success) {
            btnRef.current.click();
        }
    }, [success])

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {

        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const message = `Your account has been opened, but not yet active,
    it will processed and activated within the shortest time possible.
    Welcome to RBS`


  return (
    <div>
      <Button innerRef={btnRef} onClick={handleClick}>
          
    </Button>
     
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
              UNDO
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default SnackBar;