import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import styles from './regForm.module.css';
import { Validator } from '../../features/helpers/validator';
import { setError, clearError } from './regformSlice';

const Sec = ({state, dispatch}) => {

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [pin, setPin] = useState('');
    

    const inputChange = (e, setter) => {
        setter(e.target.value);
    }

    const validate = (e, input, inputString) => {
        const result = Validator(e, input, inputString);
        
        if(result.error){
            dispatch(setError({
                input: result.input,
                err: result.errorMessage
            }));
        } else {
            dispatch(clearError({
                input: result.input,
                val: result.inputValue
            }))
        }
    }

    return (
        <section className={styles.cover}>
            <div className={styles.headerInfo}>
                <Typography variant="h6" color="primary"> Security Details</Typography>
                <Typography variant="body2" color="primary">
                    Enter these data to secure your account
                </Typography>
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="question"> Your Security Question </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="question"
                    onChange={(e) => inputChange(e, setQuestion)}
                    required
                    onBlur={(e) => validate(e, question, "question")}
                    error={state.question !== ''}
                    helperText={
                        (state.question) ? state.question : ""
                    }
                />
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="answer"> Answer to your question </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="answer"
                    type="password"
                    onChange={(e) => inputChange(e, setAnswer)}
                    required
                    onBlur={(e) => validate(e, answer, "answer")}
                    error={state.answer !== ''}
                    helperText={
                        (state.answer) ? state.answer : ""
                    }
                />
            </div>
                
            <div className={styles.formGroup}>
                <label htmlFor="pin"> Pin </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    type="password"
                    id="pin"
                    onChange={(e) => inputChange(e, setPin)}
                    required
                    onBlur={(e) => validate(e, pin, "pin")}
                    error={state.pin !== ''}
                    helperText={
                        (state.pin) ? state.pin : ""
                    }
                />
            </div>

        </section>
    )
}

export default Sec;