import React, { useState } from 'react';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';
import { securityBannerImg, fscsProtectedImg } from '../../images';
import { Grid, Hidden } from '@material-ui/core';
import styles from './login.module.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorIcon from '@material-ui/icons/Error';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './loginSlice';
import AuthHeader from '../../features/auth-header/AuthHeader'

const Login = () => {
    
    const [cName, setCname] = useState('');
    const [pwd, setPwd] = useState('');
    //this variable is used for checking if the password has been set
    const [togglePwd, setTogglePwd] = useState(false);

    const dispatch = useDispatch(login);
    const { loading, error } = useSelector(state => state.login)

    let handleForm = () => {
      // if you're here, then password hasn't been set
      //set the password or else:
      if(togglePwd === false){
        setTogglePwd(true)
      } else {
        // after password has been set, then you've got all data
        // send to server for processing
        dispatch(login({one: cName, two: pwd}))
      }
    }

    return(
      <section>
          <AuthHeader goto="application" />

          <section>
            <ContainerMaxWidth>
              { error &&
                <Grid container>
                  <Hidden xsDown> <Grid item sm={3}></Grid> </Hidden>
                  <Grid item xs={12} sm={8}>
                    <div className={styles.error}>
                      <div className={styles.finishUp}>
                        <ErrorIcon color="secondary" style={{fontSize: '3em'}} /> &nbsp;
                        <h4>
                          Please review the following and make appropriate changes
                          or contact the server admin:
                        </h4>
                      </div>

                      <p> {error} </p>
                    </div>
                  </Grid>
                </Grid>
              }


              <Grid container>
                <Grid item sm={2}></Grid>
                <Grid item sm={10}>
                  <div className={styles.loginHeading}>
                    <p> Digital Banking Services </p>
                    <img src={fscsProtectedImg} alt="Fscs Proteced" />
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Hidden xsDown>
                  <Grid item sm={4} md={3}>
                    <img src={securityBannerImg} alt="Security Banner"/>
                  </Grid>
                </Hidden>

                <Grid item xs={12} sm={7} md={8} className={styles.formContainer}>
                  <div className={styles.steps}>
                    <p> Log in - step 1 </p>
                  </div>

                  <form className={styles.form}>
                    <p>
                      Choose how you'd like to log in. You can use your customer number or
                      your card number.
                    </p>

                    <RadioGroup value={cName} aria-label="login-method" name="loginMethod">
                      <div className={!togglePwd ? styles.show: styles.hide}>
                        <FormControlLabel
                          style={{fontSize: '2em'}} value="cName"
                          control={<Radio />} label="Customer User Name"
                          selected
                        />

                        <div className={styles.formInstruction}>
                          <p> This will be a combination of alphabets and numbers, just as you desire. </p>

                          <TextField
                            variant="outlined" size="small" value={cName}
                            onChange={e => setCname(e.target.value)}
                            type="text" className={styles.input}
                          />

                        <p><a href="/login" className={styles.formLinks}> Forgotten your customer name? </a> </p>
                        </div>
                      </div>

                      <div className={togglePwd ? styles.show: styles.hide}>
                        <FormControlLabel
                          style={{fontSize: '2em'}} value="pwd"
                          control={<Radio />} label="Your Password"
                        />

                        <div className={styles.formInstruction}>
                          <div className={styles.finishUp}>
                            <p> Only you should have this information </p>
                            <span onClick={() => setTogglePwd(false)}> <ArrowBackIcon /></span>
                          </div>

                          <TextField
                            variant="outlined" size="small" value={pwd}
                            onChange={e => setPwd(e.target.value)}
                            type="password" className={styles.input}
                          />

                          <p><a href="/login" className={styles.formLinks}> Forgotten your password? </a> </p>
                        </div>
                      </div>


                      <br />
                      <FormControlLabel disabled value="male" control={<Radio />} label="Card Number" />
                    </RadioGroup>

                    <br />

                    <FormControlLabel
                       value="end"
                       control={<Checkbox color="secondary" />}
                       label="Remember me"
                       labelPlacement="end"
                     />
                     <span>
                      <span className={styles.formLinks}>
                        What does this mean?
                      </span>
                      <span className={styles.expandMore}> <ExpandMoreIcon /> </span>
                    </span>
                    <p className={styles.rememberWarning}>
                      By enabling the functional cookie and choosing Remember me,
                      your customer number will be saved to your computer or device for
                      future visits. If you use your card number we will not save this,
                      but we will use it to identify your customer number so we can still
                      remember you. We do not recommend using this if you are using a public
                      or shared computer.
                    </p>

                    {
                      loading ?
                      <div>
                        <LinearProgress />
                        <LinearProgress color="secondary" />
                      </div>
                      : null
                    }
                  </form>
                </Grid>

                <Grid container style={{marginTop: '25px'}}>
                  <Grid item sm={3}></Grid>
                  <Grid item sm={8}>
                    <div className={styles.finishUp}>
                      <p> Not an online user? <a href="/application" className={styles.formLinks}> Sign up here </a> </p>

                      <button
                        className={styles.submitButton}
                        onClick={handleForm} disabled={(togglePwd && pwd.length<4 || cName.length<4 || loading) ? true : false}>
                        {togglePwd ? 'Continue' : 'Enter Password'}
                      </button>
                    </div>

                    <p className={styles.whoCanSignUp}>
                      Only individuals who have a Royal Bank of Scotland account and authorised
                      access to Digital Banking should proceed beyond this point. For the
                      security of customers, any unauthorised attempt to access customer bank
                      information will be monitored and may be subject to legal action.
                    </p>
                  </Grid>
                </Grid>


            </Grid>
          </ContainerMaxWidth>
        </section>
      </section>
    )
}

export default Login;
