import React from 'react';
import styles from './personalHome.module.css';
import imageTextStyles from '../../features/image-text/ImageText.module.css';
import { switchData } from '../../data';
import { moneyFactsImg, noCallsImg, serviceQualityImg } from '../../images/';
import PageTop from '../../features/page-top/PageTop';
import ImageService from '../../features/image-service/ImageService';
import { personalImageServices } from '../../data';
import ImageText from '../../features/image-text/ImageText';
import ThreeImageCard from '../../features/three-image-card/ThreeImageCard';


const PersonalImageText1 = () => {
  return (
    <section>
      <h2> Looking for a loan? </h2>

      <p>
        If you’re still considering taking out a loan, we have good news:
        you could borrow between £1,000 and £50,000.
      </p>

      <h3>
        Representative 3.9% APR for loans between £7,500 and £19,950.
      </h3>

      <p>
        Other amounts available at alternative rates. Rates depend on your
        circumstances and loan amount and may differ from the Representative
        APR.
      </p>

      <p>
        To apply, you must be 18+ and a UK resident with a Royal Bank current
        account (held for 3+ months).
      </p>

      <button className={imageTextStyles.signupBtn}>
        <a href="/application"> Apply Now </a>
      </button>
    </section>
  )
}


const PersonalImageText2 = () => {
  return (
    <section style={{color: 'white'}}>
      <h1> Prefer texting over calling? </h1>

      <p>
        Our app comes with Secure Messaging which lets you speak with us by
        text. It is available 24/7 and is designed to help make your banking
        easy, quick and secure.
      </p>

      <p>
        App available to customers aged 11+ with compatible iOS and Android
        devices and a UK or international mobile number in specific countries.
      </p>

      <br />
    </section>
  )
}

const PersonalImageText3 = () => {
  return (
    <section>
      <h1 style={{fontSize: '1.65em', letterSpacing: '1.5px', color: '#002D64'}}> Independent
        service quality survey results - Personal Current Accounts
      </h1>

      <p>
        As part of a regulatory requirement, an independent survey was conducted to ask
        customers of the largest 16 personal current account providers if they would
        recommend their provider to friends or family. Aug 2020.
      </p>
    </section>
  )
}



const PersonalHome = () => {
  return (
    <section>

      <PageTop
        header="Discover our range of Current Accounts"
        buttonText="Explore all Current Accounts"
        buttonLink="personal/bank-accounts"
        bgImage = "personal"
        displayBtn = {true}
       />

     <ImageService imageServiceData={personalImageServices} />

     <ImageText
       PageText={PersonalImageText1}
       image={moneyFactsImg}
      />




      <section className={styles.noCalls}>
        <ImageText
          PageText={PersonalImageText2}
          image={noCallsImg}
          floatImageRight={true}
         />
      </section>


      <ThreeImageCard cardData={switchData[0]} />


      <ImageText
        PageText={PersonalImageText3}
        image={serviceQualityImg}
        floatImageRight={true}
      />

    </section>
  )
}

export default PersonalHome;
