import React, { useState }  from 'react';
import { Container, Grid, Box, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { useSelector, useDispatch } from 'react-redux';
import { changeData } from './kinSlice';

const Kin = () => {

    const { user: {kin} } = useSelector(state => state.account);  
    const { error } = useSelector(state => state.kin);
    
    const dispatch = useDispatch(changeData);

    const [ kinname, setKinname] = useState('');
    const [ kinnumber, setKinnumber] = useState('');
    //const [ kinaddress, setKinAddress] = useState('');
    const [ kincountry, setKinCountry] = useState('');
    const [ kinlanguage, setKinLanguage] = useState('');

    const changeInfo = (where, currentVal, input) => {
        
        let data = {where, value: input};
        if((currentVal !== input) && input !== "") {
            dispatch(changeData(data));
        }
    }

    const changeInput = (e, setter) => {
        setter(e.target.value);
    }

    return (
        <section>
            <Box mt={4}>
                <Container maxWidth="sm">
                    <form noValidate>
                        <Grid container spacing={2}>
                            {error && <Grid item xs={12}>
                                <h4 style={{color: 'red'}}>{error}</h4>
                            </Grid>}
                            <Grid item xs={12}>
                                <Typography variant="h6" color="primary"> Account Settings -> Next of Kin</Typography>
                                <Typography variant="body2" color="primary">
                                    If you intend to change the data, type into the text field and click away
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    value={kinname}
                                    onChange={(e) => changeInput(e, setKinname)}
                                    onBlur={() => changeInfo("kin.kinname", kin.kinname, kinname)}
                                    margin="normal"fullWidth id="kin_name" label={kin.kinname} 
                                />

                                <TextField
                                    value={kinnumber}
                                    onChange={(e) => changeInput(e, setKinnumber)}
                                    onBlur={() => changeInfo("kin.kinnumber", kin.kinnumber, kinnumber)}
                                    margin="normal"fullWidth id="kin_number" label={kin.kinnumber} 
                                />

                                
                                <TextField disabled margin="normal" fullWidth id="kin_address" label={kin.kinaddress} />

                                

                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    value={kincountry}
                                    onChange={(e) => changeInput(e, setKinCountry, "kin.kincountry")}
                                    onBlur={() => changeInfo("kin.kincountry", kin.kincountry, kincountry)}
                                    margin="normal"fullWidth id="kin_country" label={kin.kincountry} 
                                />

                                <TextField
                                    value={kinlanguage}
                                    onChange={(e) => changeInput(e, setKinLanguage, "kin.kinlanguage")}
                                    onBlur={() => changeInfo("kin.kinlanguage", kin.kinlanguage, kinlanguage)}
                                    margin="normal"fullWidth id="kin_language" label={kin.kinlanguage} 
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Container>  
            </Box> 
        </section>
    )
}

export default Kin