import React from 'react';
import { Container } from '@material-ui/core';

const ContainerMaxWidth = ({ children }) => {
  return (
    <Container maxWidth="md">
      {children}
    </Container>
  )
}

export default ContainerMaxWidth;
