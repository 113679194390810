import React from 'react';
import SiteMap from '../../features/site-map/SiteMap';
import PageTop from '../../features/page-top/PageTop';
import styles from './financialHealth.module.css';
import { Container, Grid } from '@material-ui/core';
import ThreeImageCard from '../../features/three-image-card/ThreeImageCard';
import { switchData, accordionData } from '../../data';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

const FinancialHealth = () => {
    return (
      <section>
        <SiteMap sliceIndex={2} />
        <br />

        <PageTop
          header="Financial Health Check"
          buttonText="Explore all Current Accounts"
          bgImage = "financialHealthCheck"
          displayBtn = {false}
         />

         <hr />

         <Container maxWidth="md">
          <Grid container>
            <Grid item xs={12} sm={8}>
              <div className={styles.finanHealth}>
               <h1 className={styles.h1}> What's a Financial Health Check? </h1>

               <p>
                Get your finances in good shape, discover helpful hints and tips for everyday
                spending or focus on something specific for the future.
               </p>

               <ol>
                <li>
                  Checking in on your money regularly beyond balance and statement will help
                  you stay financially fit.
                </li>
                <li> Explore the priority areas below – from home ownership to
                  planning for the future, a Financial Health Check can kickstart
                  some good money habits that could last a lifetime.
                 </li>
                </ol>

                <br /><br />
                <h1 className={styles.h1}> Three ways to improve your finances today </h1>
                <p>
                  There are plenty of things big and small you can do today that can
                  make a big impact on your financial future.
                </p>
              </div>
            </Grid>
          </Grid>
         </Container>

         <ThreeImageCard cardData={switchData[5]} />

         <Container maxWidth="md">
          <Grid container>
            <Grid item xs={12} sm={8}>
              <h1 className={styles.h1}> What are your priorities ? </h1>

              <p>
                You tell us what your priorities are and we'll share helpful tips and tools.
                Start making an impact on your financial future, now.
              </p>
              <br />
              <h2 style={{color: '#002D64'}}> Common questions about Financial Health Checks </h2>
            </Grid>

            <Grid item>
              <div className={styles.accordionRoot}>
                {
                  accordionData.map((acc, index) => (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<AddIcon style={{color: '#002D64'}} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={styles.heading}>{acc.accordionHeading}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p dangerouslySetInnerHTML={{__html: acc.accordionText}}>
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  ))
                }
              </div>
            </Grid>
          </Grid>
         </Container>
         <br /><br /><br /><br />
      </section>
    )
}

export default FinancialHealth;
