import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import styles from './LowerNav.module.css';
import { NavLink } from 'react-router-dom';

const LowerNav = ({ routingData }) => {
  return (
    <Hidden smDown>
      <Grid container item>
        <Grid item md={2} lg={1}>
          <span className={styles.centerElement}>
            <img src="https://i.ibb.co/dp157Bh/rbs-logo.png" alt="rb-s"  width="50%" />
          </span>
        </Grid>

        <Grid item md={9} lg={10}>
          <nav className={styles.lowerLinks}>
            {
              routingData.map((route, index) => (
                <NavLink key={index} activeClassName={styles.lowerLinksActive} to={route.to}>
                  {route.linkText}
                </NavLink>
              ))
            }
          </nav>
        </Grid>

        <Grid item md={1}>
          <div className={styles.loginLink}>
            &nbsp; &nbsp; 
            <NavLink style={{
              position: 'relative', top: '15px', fontWeight: 'bolder'
              }} activeClassName={styles.isActive} to='/application'>
               Join Us
            </NavLink>
          </div>
        </Grid>
      </Grid>
    </Hidden>
  )
}

export default LowerNav;
