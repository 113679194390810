import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import styles from './PageTop.module.css';

const PageTop = ({ header, buttonText, buttonLink, bgImage, displayBtn}) => {

  let backStyle;

  switch(bgImage) {
      case 'personal':
        backStyle = styles.personalBgImage;
        break;
      case 'premier':
        backStyle = styles.premierBgImage;
        break;
      case 'business':
        backStyle = styles.businessBgImage;
        break;
      case 'contact':
        backStyle = styles.contactBgImage;
        break;
      case 'support':
        backStyle = styles.supportBgImage;
        break;
      case 'financialHealthCheck':
        backStyle = styles.financialHealthBgImage;
        break;
      case 'premierProducts':
        backStyle = styles.premierProductsBgImage;
        break;
      case 'premierSelect':
        backStyle = styles.premierSelectBgImage;
        break;
      case 'premierSupport':
        backStyle = styles.premierSupportBgImage;
        break;
      default:
        backStyle = styles.personalBgImage
  }

  return (
      <Grid container>
        <Grid item xs={12} className={styles.homeImage + ' ' + backStyle}>
          <Hidden xsDown>
            <Grid container item>
              <Grid item xs={1} sm={2}></Grid>
              <Grid item xs={12} sm={7} lg={4}>
                <div className={styles.imageTitle}>
                  <h1> {header} </h1>
                </div>
              </Grid>
              <Grid item sm={3} lg={6}></Grid>


              <Grid item xs={1} sm={2}></Grid>
                {displayBtn &&
                  <Grid item xs={8} sm={5} className={styles.currentButton}>
                    <a href={buttonLink}> {buttonText} </a>
                  </Grid>
                }
            </Grid>
          </Hidden>
        </Grid>

        <Hidden smUp>
          <Grid container item className={styles.homeImageMobile}>
            <Grid item xs={12}>
              <div className={styles.imageTitle}>
                <h1> {header} </h1>
              </div>
            </Grid>
            {displayBtn &&
              <Grid item xs={12} className={styles.currentButton}>
                <a href={buttonLink}> {buttonText} </a>
              </Grid>
            }
          </Grid>
        </Hidden>
      </Grid>
  )
}

export default PageTop;
