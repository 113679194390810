import React from 'react';
import SiteMap from '../../features/site-map/SiteMap';
import PageTop from '../../features/page-top/PageTop';
import { Container, Grid } from '@material-ui/core';
import styles from './bankAccounts.module.css';
import ImageText from '../../features/image-text/ImageText';
import { smilingWithPhoneImg } from '../../images';
import ListComponent from '../../features/list-component/ListComponent';
import { listWithTopBorder, listWithCheckData } from '../../data';
import ListWithCheck from '../../features/list-with-check/ListWithCheck';
import InfoIcon from '@material-ui/icons/Info';

const BankAccountsText = () => <ListWithCheck listData={listWithCheckData[0]} />

const BankAccounts = () => {
    return(
      <section>
        <SiteMap sliceIndex={2} />
        <br />

        <PageTop
          header="Compare our bank accounts"
          buttonText="Explore all Current Accounts"
          buttonLink="/current-account"
          bgImage = "personal"
          displayBtn = {false}
         />

       <Container maxWidth="md">
         <Grid container>
           <Grid item xs={12}>
             <h1> What type of account are you looking for ?</h1>
           </Grid>

           <Grid item xs={12} sm={6} md={4}>
             <span className={styles.links}>
               <a href="/personal">
                  Everyday bank account
               </a>
             </span>
           </Grid>


           <Grid item xs={12} sm={6} md={4}>
             <span className={styles.links}>
               <a href="/personal">
                  Reward | Student | Child
               </a>
             </span>
           </Grid>


           <Grid item xs={12} sm={6} md={4}>
             <span className={styles.links}>
               <a href="/personal">
                  Everyday bank account
               </a>
             </span>
           </Grid>

         </Grid>
       </Container>

       <hr className={styles.hr} />

       <ImageText
         PageText={BankAccountsText}
         image={smilingWithPhoneImg}
         />

         <Container maxWidth="md">
           <Grid container spacing={4}>
             <Grid item xs={12} sm={9} className={styles.ba}>
               <h1> Additional bank accounts </h1>

                <p> We also offer additional bank accounts to suit your needs </p>

                <p className={styles.centerItem}>
                  <InfoIcon className={styles.info} fontSize="large" /> &nbsp; Specific account eligibility criteria applies.
                </p>
             </Grid>


             {
               listWithTopBorder[0].map((bAList, index) => (
                 <Grid item xs={12} sm={4} key={bAList.heading}>
                   <ListComponent listData={listWithTopBorder[0][index]} />
                 </Grid>
               ))
             }


           </Grid>
        </Container>

      </section>
    )
}

export default BankAccounts;
