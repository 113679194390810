export const Validator = (event, inputValue, inputString) => {
    let capitalizedInput = inputString[0].toUpperCase() + inputString.substring(1);
    let similarVal = [
        "user", "name", "street", "city", "region", "country", 
        "language", "question", "answer", "pin", "kinname",
        "kinnumber", "kinaddress", "kincity", "kinstate",
        "kinlanguage"
    ]

    if(similarVal.includes(event.target.id)){
        if(inputValue === ""){
            return {
                error: true,
                input: inputString,
                errorMessage: `Your ${capitalizedInput} cannot be blank`
            }
        } else if(inputValue.length < 4){
            return {
                error: true,
                input: inputString,
                errorMessage: `Your ${capitalizedInput} must have at least 3 characters`
            }
        } else {
            return {error: false, input: inputString, inputValue}
        }
    }

    if(event.target.id === "email") {
        let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(inputValue === ""){
            return {
                error: true,
                input: inputString,
                errorMessage: `Your ${capitalizedInput} cannot be blank`
            }
        } else if (!inputValue.match(mailformat)){
            return {
                error: true,
                input: inputString,
                errorMessage: `You have entered and invalid email`
            } 
        } else {
            return {error: false, input: inputString, inputValue}
        }
    }

    if(event.target.id === "number") {
        if(inputValue === ""){
            return {
                error: true,
                input: inputString,
                errorMessage: `Your ${capitalizedInput} cannot be blank`
            }
        } else if (inputValue.length < 8){
            return {
                error: true,
                input: inputString,
                errorMessage: `You have entered and invalid ${capitalizedInput}`
            } 
        } else {
            return {error: false, input: inputString, inputValue}
        }
    }

    if(event.target.id === "pwd") {
        // At least one number, one lowercase and one uppercase letter
        // and at least six characters
        let pwdformat = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        if(inputValue === ""){
            return {
                error: true,
                input: inputString,
                errorMessage: `Your ${capitalizedInput} cannot be blank`
            }
        } else if (!inputValue.match(pwdformat)){
            return {
                error: true,
                input: inputString,
                errorMessage: `Your password must have at least one number, one lowercase and one uppercase letter
                and must be at least six characters`
            } 
        } else {
            return {error: false, input: inputString, inputValue}
        }
        
    }

    if(event.target.id === "vpwd") {
        //console.log(event.target.name)
        if(inputValue === ""){
            return {
                error: true,
                input: inputString,
                errorMessage: `Your ${capitalizedInput} cannot be blank`
            }
        } else if (event.target.name !== inputValue){
            return {
                error: true,
                input: inputString,
                errorMessage: `Your passwords do not match`
            } 
        } else {
            return {error: false, input: inputString, inputValue}
        }
        
    }



    let similarVal1 = ["country","dob", "kin_country", "accounttype"]

    if(similarVal1.includes(event.target.name)){
        if(inputValue === ""){
            return {
                error: true,
                input: inputString,
                errorMessage: `Your ${capitalizedInput} cannot be blank`
            }
        } else {
            return {error: false, input: inputString, inputValue}
        }
    }


}