import React from 'react';
import { Container, Grid } from '@material-ui/core';
import styles from './imageService.module.css';
import { NavLink } from 'react-router-dom';

const ImageService = ({ imageServiceData }) => {
  //console.log(typeof(imageServiceData))
  return (
    <section className={styles.personalImageServices}>
      <Container maxWidth="md">
        <Grid container className={styles.centerItem} spacing={3}>

          {
            imageServiceData.map((pi, index) => (
              <Grid item xs={12} sm={3} key={index}>
                <Grid item container>
                  <Grid item xs={3} sm={12} className={styles.centerItem}>
                    <img alt={pi.linkText} src={pi.image} height="90px" />
                  </Grid>

                  <Grid item xs={9} sm={12} className={styles.centerItem}>
                    <span className={styles.piSpan}>
                      <NavLink to={pi.to}>
                        {pi.linkText}
                      </NavLink>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            ))
          }

        </Grid>
      </Container>
    </section>
  )
}

export default ImageService;
