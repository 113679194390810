import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Grid, Hidden, Box } from '@material-ui/core';
import DashHeader from '../../dash-features/dash-header/DashHeader';

import SideMenu from '../../dash-features/side-menu/SideMenu';
import IconSideBar from '../../dash-features/icon-sidebar/IconSidebar';

import Account from '../account/Account';
import History from '../history/History';
import Kin from '../kin/Kin';
import Profile from '../profile/Profile';
import Security from '../security/Security';
import Transfer from '../transfer/Transfer';

const DashHome = () => {
    return(
      <section style={{background: '#F2F4F8'}}>
        <DashHeader />

          <Grid container style={{marginTop: '-14px'}}>
            <Hidden mdDown>
              <Grid item lg={2}>
                <Box p={2}>
                  <SideMenu />
                </Box>
              </Grid>
            </Hidden>
            <Hidden xsDown lgUp>
              <Grid item sm={1} lg={2}>
                <IconSideBar />
              </Grid>
            </Hidden>


            <Grid item xs={12} sm={10}>
              <h4 style={{paddingTop: '10px', paddingLeft: '60px', marginBottom: '-20px'}}> Financial Overview </h4>


              <Switch>
                <Route exact path="/dashboard" component={Account} />
                <Route exact path="/dashboard/account" component={Account} />
                <Route exact path="/dashboard/history" component={History} />
                <Route exact path="/dashboard/kin" component={Kin} />
                <Route exact path="/dashboard/profile" component={Profile} />
                <Route exact path="/dashboard/transfer" component={Transfer} />
                <Route path="/dashboard/security/" component={Security} />
              </Switch>
            </Grid>
          </Grid>
      </section>
    )
}

export default DashHome;
