import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Box } from '@material-ui/core';


import styles from './history.module.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const History = () => {

  const { user } = useSelector(state => state.account);

  return (
    <Grid container>
      <Grid item xs={12}>
      <Box p={2} mt={4}>
        <Card elevation={6}>
          <CardContent>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <h3> Transaction History </h3>
              <a href="#/dashboard/history"> More</a>
            </div>

            <hr style={{marginTop: '-7px'}}/>
            <TableContainer className={styles.table}>
              <Table stickyHeader aria-label="history table">
                <TableHead>
                  <TableRow>
                    <TableCell> Status </TableCell>
                    <TableCell align="right"> Date </TableCell>
                    <TableCell align="right"> Description </TableCell>
                    <TableCell align="right"> Category </TableCell>
                    <TableCell align="right"> Amount </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {user.history.map((history, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {history.Transactionstatus ?
                          (<span style={{color: 'green'}} className={styles.status}>Completed</span>)
                          :
                          (<span style={{color: 'red'}} className={styles.status}>Declined</span>)
                        }
                      </TableCell>
                      <TableCell align="right">{history.Transactiondate.slice(0,10)}</TableCell>
                      <TableCell align="right">{history.Bank}</TableCell>

                      <TableCell align="right">
                        {(
                          history.Transactiontype === 'Credit') ?
                          (<span style={{color: 'green'}} className={styles.status}>{history.Transactiontype}</span>)
                          :
                          (<span style={{color: 'red'}} className={styles.status}>{history.Transactiontype}</span>)
                        }
                      </TableCell>

                      <TableCell align="right">
                        {(
                          history.Transactiontype === 'Credit') ?
                          (<span style={{color: 'green'}} className={styles.status}>{`+${user.account.currency}${history.Amount.toLocaleString()}`}</span>)
                          :
                          (<span style={{color: 'red'}} className={styles.status}>{`-${user.account.currency}${history.Amount.toLocaleString()}`}</span>)
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  </Grid>
  )
}

export default History;
