import React from 'react';


const Nomatch = () => {
  return(
    <h1> Error 404 | NOT FOUND!!! </h1>
  )
}

export default Nomatch
