import React from 'react';
import SiteMap from '../../features/site-map/SiteMap';
import PageTop from '../../features/page-top/PageTop';
import styles from './corona.module.css';
import { Container, Grid } from '@material-ui/core';
import ImageText from '../../features/image-text/ImageText';
import { scaleImg, smilingWomanImg } from '../../images';

const coronaImageText = () => {
  return (
    <section>
      <p>
        As the coronavirus outbreak continues, we all face the difficult
        challenge of responding to the impact it is having on our lives.
        We are here to support you through these uncertain times.
      </p> <br />

      <p>
        We understand this is a difficult time for many of our
        customers. To support you, following the latest government
        coronavirus announcements, we've updated our guidance on
        support for mortgages, loans, credit cards and overdrafts.
        You'll continue to find details of how we can help on this
        page.
      </p> <br />

      <p>
        Please help us to support those most vulnerable by only
        calling or visiting our branches if it’s absolutely critical,
        and you are unable to do what you need to on our app or
        Digital Banking. If you need help with completing your
        banking this way, our webchat and Message Us support are
        there for you.
      </p> <br />
    </section>
  )
}

const coronaImageText2 = () => {
  return (
    <section>
      <h2> Support for customers over 60 and in extended isolation </h2>

      <p>
        Useful guides, details of our dedicated support line and
        information for those of us self-isolating for health or age
        related reasons.
      </p> <br /><br /><br /><br />
    </section>
  )
}

const Corona = () => {
  return (
    <section>
      <SiteMap sliceIndex={2} />
      <br />

      <PageTop
        header="Royal Bank is here to support you"
        buttonText="Explore all Current Accounts"
        buttonLink="/current-account"
        bgImage = "support"
        displayBtn = {false}
       />

       <section className={styles.coronaUpdate}>
        <Container maxWidth="md">
          <Grid container>
            <Grid item xs={12} md={6} >
              <h4> Last updated: 2nd December 2020, 13:00 </h4>
              <h2> An update from Royal Bank on coronavirus </h2>
            </Grid>
          </Grid>
        </Container>
       </section>

       <ImageText
         PageText={coronaImageText}
         image={scaleImg}
         floatImageRight={true}
        />


       <ImageText
         PageText={coronaImageText2}
         image={smilingWomanImg}
        />



    </section>
  )
}

export default Corona;
