import { createSlice } from '@reduxjs/toolkit';
import api from '../../../../features/api/axios';

const securitySlice = createSlice({
    name: 'securityRd',
    initialState: {
        error: '',
        email: '',
        number: '',
        address: '',
        language: '',
        tSuccess: ''
    },
    reducers: {
        setError: (state, action) => {
            state.error = action.payload
        },
        doneEditing: (state, action) => {
            state.tSuccess = action.payload;
            state.error = "";
        },
    }
})

export const { doneEditing, setError } = securitySlice.actions;

export default securitySlice.reducer;

export const changeData = (data) => async (dispatch) => {
    try {
        const response = await api.put("/security", data);
        if(response.data.success){
            console.log(response.data.success);
            dispatch(doneEditing(response.data.success));
        } else {
            dispatch(setError(response.data.error));
        }
    } catch (err) {
        dispatch(setError("An error occured, please try again!!!"));
    }
}