import { createSlice } from '@reduxjs/toolkit';
import api from '../../features/api/axios';


const dashSlice = createSlice({
  name: 'dashboardRd',
  initialState: {
    user: {},
    error: '',
    loading: true
  },
  reducers: {
    fetchProfile: (state) => {
      state.loading = true
    },
    profileSuccess: (state, action) => {
      return {...state, error: '', user: action.payload, loading: false}
    },
    profileError: (state, action) => {
      return {...state, error: action.payload}
    }
  }
})

export const { fetchProfile, profileSuccess, profileError } = dashSlice.actions;

export default dashSlice.reducer;

//Thunks

export const getProfile = () => async dispatch => {
  try {
    dispatch(fetchProfile);
    //First grab the username from localStorage if it's saved
    const theUser = localStorage.getItem('user');

    if(theUser){
      const userInfo = JSON.parse(localStorage.getItem('user'));
      var uname = userInfo.uname;

      const response = await api.get(`/profile/${uname}`);
      const user = response.data[0];
      //console.log(user);
      dispatch(profileSuccess(user));
    } else {
      throw new Error("You're not logged in, please login first")
    }

  } catch (err) {
    alert(err)
    window.location.replace("/#/login")
  }
}