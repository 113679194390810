import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import styles from './regForm.module.css';
import { Validator } from '../../features/helpers/validator';
import { setError, clearError } from './regformSlice';

const Sec = ({state, dispatch}) => {

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [vpwd, setVPwd] = useState('');
    

    const inputChange = (e, setter) => {
        setter(e.target.value);
    }

    const validate = (e, input, inputString) => {
        const result = Validator(e, input, inputString);
        
        if(result.error){
            dispatch(setError({
                input: result.input,
                err: result.errorMessage
            }));
        } else {
            dispatch(clearError({
                input: result.input,
                val: result.inputValue
            }))
        }
    }
    

    return (
        <section className={styles.cover}>
            <div className={styles.headerInfo}>
                <Typography variant="h6" color="primary"> Authentication Data</Typography>
                <Typography variant="body2" color="primary">
                    Enter your authentication data
                </Typography>
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="name"> Choose a unique username </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="user"
                    onChange={(e) => inputChange(e, setUser)}
                    required
                    onBlur={(e) => validate(e, user, "user")}
                    error={state.user !== ''}
                    helperText={
                        (state.user) ? state.user : ""
                    }
                />
            </div>

            <div className={styles.formGroup}>
                <label htmlFor="pwd"> Enter a strong password </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    id="pwd"
                    type="password"
                    onChange={(e) => inputChange(e, setPwd)}
                    required
                    onBlur={(e) => validate(e, pwd, "pwd")}
                    error={state.pwd !== ''}
                    helperText={
                        (state.pwd) ? state.pwd : ""
                    }
                />
            </div>
                
            <div className={styles.formGroup}>
                <label htmlFor="vpwd"> Verify your password </label>
                <TextField 
                    size="small" 
                    variant="outlined"
                    type="password"
                    name={pwd}
                    id="vpwd"
                    onChange={(e) => inputChange(e, setVPwd)}
                    required
                    onBlur={(e) => validate(e, vpwd, "vpwd")}
                    error={state.vpwd !== ''}
                    helperText={
                        (state.vpwd) ? state.vpwd : ""
                    }
                />
            </div>

        </section>
    )
}

export default Sec;