import React from "react";
import { options } from '../../data';
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";

import { useSelector, useDispatch } from 'react-redux';
import { toggleMenu, selectToggle } from '../sidemenu/sideMenuSlice';

import './sideMenu.module.css';




const SideMenu = () => {

  const toggled = useSelector(selectToggle);
  const dispatch = useDispatch();


  return (
    <div>
      <MultilevelSidebar
        open={toggled}
        onToggle={() => dispatch(toggleMenu())}
        options={options}
        header="* Royal Bank of Scotland *"
      />
      {/* using in our button to open the sidebar */}
      <img
        src={toggled ? 'https://i.ibb.co/9ZvgCPj/closePad.jpg':'https://i.ibb.co/10KpvDc/menu.jpg'}
        onClick={() => dispatch(toggleMenu())}
        style={{height: 'auto', width: '100%', cursor: 'pointer'}}
        alt="Menu"
       />
    </div>
  )
}

export default SideMenu;
