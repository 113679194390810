import React from 'react';
import AuthHeader from '../../features/auth-header/AuthHeader'
import ListWithCheck from '../../features/list-with-check/ListWithCheck';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';
import BorderContainer from '../../features/border-container/BorderContainer';
import { Grid, Typography, Box, Paper, Hidden } from '@material-ui/core';
import { listWithCheckData } from '../../data';

import Auth from '../reg-form/Auth';
import Biography from '../reg-form/Biography';
import Sec from '../reg-form/Sec';
import Kin from '../reg-form/Kin';

import { useSelector, useDispatch } from 'react-redux';
import { setError, applyNow } from '../reg-form/regformSlice';
import styles from './application.module.css';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';

import SnackBar from '../../features/snackbar/SnackBar'


const Application = () => {
    
    const state = useSelector(state => state.regForm);
    const dispatch = useDispatch(setError);

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = (fs) => {
        if(verifyState(fs) !== null){
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const auth = ["user", "pwd"];

    const bio = [
        "name", "email", "number", "street", "city", 
        "region", "country", "dob", "language"
    ];

    const sec = ["question", "answer", "pin"];

    const kin = [
        "kinname", "kinaddress", "kincity", "kinstate",
        "kincountry", "kinlanguage"
    ];

    const steps = [
        {
            label: 'Authentication',
            formComp: Auth,
            formState: auth
        },
        {
            label: 'Biography',
            formComp: Biography,
            formState: bio
        },
        {
            label: 'Security',
            formComp: Sec,
            formState: sec
        },
        {
            label: 'Kin',
            formComp: Kin,
            formState: kin
        },
    ]

    const verifyState = (formState) => {
        let ls = formState
        for(let i=0; i<ls.length; i++) {
            let val = state["formData"][ls[i]];
            if(val === ""){
                dispatch(setError({
                    input: ls[i],
                    err: `Your ${ls[i]} cannot be empty`
                }));
                return null;
            }
        }
    }

    const submitForm = () => {
        dispatch(applyNow(state.formData));
    }

    return (
        <section>
            { state.loading && 
                <div className={styles.loading}>
                    <CircularProgress color="secondary" className={styles.icon} />
                </div>
            }

            <AuthHeader goto="login" />

            <ContainerMaxWidth>
                <Grid container>
                    <Grid item xs={12}>
                        <Box mt={4}>
                            <Typography variant="h5">
                                Set up Digital Banking
                            </Typography>
                            <br />
                            <Typography variant="body2">
                                Setting up Digital Banknig will only take a few minutes. If we have
                                a mobile number for you, we can set you up for Digital Banking quickly
                            </Typography>
                            <br />
                            <Typography variant="body2">
                                Before you start, please make sure
                            </Typography>

                            <ListWithCheck listData={listWithCheckData[3]} />
                        </Box>
                    </Grid>
                </Grid>
            </ContainerMaxWidth>

            { state.appError &&
                <Grid container>
                  <Hidden xsDown> <Grid item sm={3}></Grid> </Hidden>
                  <Grid item xs={12} sm={6}>
                    <div className={styles.error}>
                      <div className={styles.finishUp}>
                        <ErrorIcon color="secondary" style={{fontSize: '3em'}} /> &nbsp;
                        <h4>
                          Please review the following and make appropriate changes
                          or contact the server admin:
                        </h4>
                      </div>

                      <p> {state.appError} </p>
                    </div>
                  </Grid>
                </Grid>
              }
              <br />
            <BorderContainer header="Registration">
                <Box mt={2}>
                    <form noValidate>
                        <Stepper activeStep={activeStep} orientation="vertical">
                           {steps.map((form, index) => (
                                    <Step key={index}>
                                        <StepLabel> {form.label} </StepLabel>
                                        <StepContent>
                                            <form.formComp 
                                                state={state} 
                                                dispatch={dispatch}
                                            />
                                            <div>
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                >
                                                    Back
                                                </Button> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleNext(form.formState)}
                                                >
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button>
                                            </div>
                                        </StepContent>
                                    </Step>
                                ))
                            }
                        </Stepper>

                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={styles.completed}>
                                <Typography>All steps completed - you&apos;re Done</Typography>
                                    <br />
                                    <Button 
                                        variant="contained" color="secondary" 
                                        startIcon={<ArrowBackIcon />}
                                        onClick={handleReset}
                                    >
                                        Reset
                                    </Button> &nbsp; &nbsp;

                                    <Button 
                                        variant="contained" color="primary" 
                                        endIcon={<ArrowForwardIcon />}
                                        onClick={submitForm}
                                    >
                                        SUBMIT APPLICATION
                                    </Button>
                                    <br /><br /> <br />
                            </Paper>
                        )}
                    </form>
                </Box>
                    
            </BorderContainer>

            <SnackBar state={state} />

        </section>
    )  
}

export default Application