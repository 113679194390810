import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from './dashboardSlice';
import Loading from './dash-features/loading/Loading';
import DashHome from './dash-comp/dash-home/DashHome';

const Dashboard = () => {
  document.title="Operate your rbs acct from here"

  const dispatch = useDispatch(getProfile);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch])
  
  const { loading } = useSelector(state => state.account);



  return (
    <section>
      {
        loading ?
        <Loading /> :
        <DashHome />
      }
    </section>
  )
}

export default Dashboard;
