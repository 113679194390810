import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Container } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import Button from '@material-ui/core/Button';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SendIcon from '@material-ui/icons/Send';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import styles from './transfer.module.css';

import { useSelector, useDispatch } from 'react-redux';
import { setError, clearError, initiateTransfer } from './transferSlice';

import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import FiberPinIcon from '@material-ui/icons/FiberPin';

import MoneyIcon from '@material-ui/icons/Money';
import PublicIcon from '@material-ui/icons/Public';

import { makeStyles } from '@material-ui/core/styles';

import { countryList } from '../../../../data';

import CircularProgress from '@material-ui/core/CircularProgress';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));


const Transfer = () => {

    const state = useSelector(state => state.transfer);
    const m_a = useSelector(state => state.account);
    const mainAccount = m_a.user.account.accountno;

    const savedPin = m_a.user.security.pin;

    useEffect(() => {
        // const formCompleted = () => {
        //     if(state.tSuccess || state.tError){
        //         inputRef.current.click();
        //     }
        // }
        if(state.tSuccess || state.tError){
            inputRef.current.click();
        }
    }, [state.tSuccess, state.tError]);
    

    const [open, setOpen] = React.useState(false);
    // Defining the ref constant variable
    const inputRef = useRef(null);
    
    

    const dispatch = useDispatch(setError)

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const [account, setAccount] = React.useState('');
    const [name, setName] = React.useState('');
    const [accountNo, setAccountNo] = React.useState('');
    const [routingNo, setRoutingNo] = React.useState('');
    const [amount, setAmount] = React.useState('');
    const [bnk, setBnk] = React.useState('');
    const [pin, setPin] = React.useState('');

    const [currency, setCurrency] = useState('');
    const [bnkAddress, setBnkAddress] = useState('');
    const [country, setCountry] = useState('');
    const [purpose, setPurpose] = useState('');
    const [empty, setEmpty] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);

        // clear all state fields
        if(state.tSuccess){
        [
                setAccount, setName, setAccountNo, setRoutingNo, setAmount,
                setBnk, setPin, setCurrency, setBnkAddress, setCountry, setPurpose
            ].map((setter) => setter(""));
        }

        dispatch(clearError({input: "tSuccess"}));
        dispatch(clearError({input: "tError"}));
    };
    

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    
    const handleInputChange = (event, setter) => {
        setter(event.target.value);
    };

    const submitForm = (region) => {
        // console.log(typeof(savedPin));
        // console.log(typeof(parseInt(pin)));
        if (savedPin !== parseInt(pin)){
            alert("That pin is wrong, please, use the right one");
            return;
        } else {

        let formData = {};
            if (region === "International"){
                formData = {
                    account, name, accountNo, routingNo, amount,
                    bnk, pin, currency, bnkAddress, country, purpose
                }
            } else {
                formData = {
                    account, name, accountNo, routingNo, amount,
                    bnk, pin
                }
            }
            //loop throug the formdata to check for empties
            const values = Object.values(formData);

            for (const value of values){
                if(value === "") {
                    setEmpty('Make sure to fill in all entries!!')
                }
            }

            dispatch(initiateTransfer(formData));
        }

        
    }

    const validateMe = (e, inputValue) => {
        if(empty !== ""){
            setEmpty('');
            return;
        }
        
        if(e.target.id === "name"){
            if(inputValue.length < 3){
                dispatch(setError({
                    input: "name",
                    err: 'Name must have at least 3 characters'
                }));
            } else {
                dispatch(clearError({input: "name"}))
            }
        }
        
        if(e.target.id === "accountNo"){
            if(inputValue.length < 6){
                dispatch(setError({
                    input: "accountNo",
                    err: "Please enter the correct account number"
                }));
            } else {
                dispatch(clearError({input: "accountNo"}))
            }
        }

        if(e.target.id === "routingNo"){
            if(inputValue.length < 6){
                dispatch(setError({
                    input: "routingNo",
                    err: "Please enter the correct Routing number"
                }));
            } else {
                dispatch(clearError({input: "routingNo"}))
            }
        }

        if(e.target.id === "amount"){
            if(inputValue.length < 1){
                dispatch(setError({
                    input: "amount",
                    err: "Please enter the amount you intend to send"
                }));
            } else {
                dispatch(clearError({input: "amount"}))
            }
        }

        if(e.target.id === "bnk"){
            if(inputValue.length < 4){
                dispatch(setError({
                    input: "bnk",
                    err: "Please enter the Receiver's Bank Name"
                }));
            } else {
                dispatch(clearError({input: "bnk"}))
            }
        }


        if(e.target.id === "pin"){
            if(inputValue.length < 4 || inputValue.length > 4){
                dispatch(setError({
                    input: "pin",
                    err: "That pin seems wrong, please try again with the right one!"
                }));
            } else {
                dispatch(clearError({input: "pin"}))
            }
        }

        if(e.target.id === "currency"){
            if(inputValue.length < 1){
                dispatch(setError({
                    input: "currency",
                    err: "Enter the currency in which you intend to transact in."
                }));
            } else {
                dispatch(clearError({input: "currency"}))
            }
        }


        if(e.target.id === "bnkAddress"){
            if(inputValue.length < 8){
                dispatch(setError({
                    input: "bnkAddress",
                    err: "Enter the bank's address of the account you're sending to"
                }));
            } else {
                dispatch(clearError({input: "bnkAddress"}))
            }
        }

        if(e.target.id === "country"){
            if(inputValue.length < 4){
                dispatch(setError({
                    input: "country",
                    err: "Enter the country you're sending money to"
                }));
            } else {
                dispatch(clearError({input: "country"}))
            }
        }


        if(e.target.id === "purpose"){
            if(inputValue.length < 7){
                dispatch(setError({
                    input: "purpose",
                    err: "Tell us why you're sending this money"
                }));
            } else {
                dispatch(clearError({input: "purpose"}))
            }
        }







    }

    return (
        <section>
            <br /><br />
            <Box p={2}>
                <Container maxWidth="sm">
                    <div className={classes.root}>
                        {empty && <h4 style={{color: 'red'}}> {empty} </h4>}
                        
                        
                        <Accordion 
                            expanded={expanded === 'panel1'} 
                            onChange={handleChange('panel1')}
                            style={{
                                background: state.loading ? 'rgba(1, 21, 23, .5)' : ''
                            }}
                            >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                >
                                <Typography className={classes.heading}>National Transfer</Typography>
                                <Typography className={classes.secondaryHeading}>Transfers within the country</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                            <form id="nationalForm" noValidate className={classes.root}>
                                <TextField
                                    color="primary"
                                    margin="normal"
                                    fullWidth
                                    id="accountno"
                                    select
                                    label="Debit from"
                                    value={account}
                                    onChange={(e) => handleInputChange(e,setAccount)}
                                    helperText="Please select the account you want to be debited from"
                                    >
                                        <MenuItem value="Main account - 75746873">
                                            Main account - {mainAccount}
                                        </MenuItem>
                                </TextField>
                                
                                <Grid container spacing={1} alignItems="flex-end" >
                                    <Grid item xs={1}>
                                        <PersonAddIcon className={styles.inputIcon} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            onChange={(e) => handleInputChange(e, setName)}
                                            value={name}
                                            color="primary"
                                            margin="normal"
                                            required
                                            size="small"
                                            fullWidth
                                            id="name"
                                            label="Receiver's Name"
                                            variant="outlined"
                                            error={state.name !== ''}
                                            helperText={
                                                (state.name) ? 
                                                state.name :
                                                "Who are you sending money to ?"
                                            }
                                            onBlur={(e)=>validateMe(e, name)}
                                            />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item xs={1}>
                                        <FormatListNumberedIcon className={styles.inputIcon} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            onChange={(e) => handleInputChange(e, setAccountNo)}
                                            value={accountNo}
                                            color="primary"
                                            margin="normal"
                                            required
                                            size="small"
                                            fullWidth
                                            id="accountNo"
                                            label="Account number"
                                            variant="outlined"
                                            error={state.accountNo !== ''}
                                            helperText={
                                                (state.accountNo) ? 
                                                state.accountNo :
                                                "Receiver's account number"
                                            }
                                            type="Number"
                                            onBlur={(e)=>validateMe(e, accountNo)}
                                            />
                                    </Grid>
                                </Grid>
                                

                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item xs={1}>
                                        <FormatListNumberedIcon className={styles.inputIcon} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            onChange={(e) => handleInputChange(e, setRoutingNo)}
                                            value={routingNo}
                                            color="primary"
                                            margin="normal"
                                            required
                                            size="small"
                                            fullWidth
                                            id="routingNo"
                                            label="Routing number"
                                            variant="outlined"
                                            error={state.routingNo !== ''}
                                            helperText={
                                                (state.routingNo) ? 
                                                state.routingNo :
                                                "Receiver's routing number"
                                            }
                                            type="Number"
                                            onBlur={(e)=>validateMe(e, routingNo)}
                                            />
                                    </Grid>
                                </Grid>
                                
                                
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item xs={1}>
                                        <AttachMoneyIcon className={styles.inputIcon} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            onChange={(e) => handleInputChange(e, setAmount)}
                                            value={amount}
                                            color="primary"
                                            margin="normal"
                                            required
                                            size="small"
                                            fullWidth
                                            id="amount"
                                            label="Amount"
                                            variant="outlined"
                                            error={state.amount !== ''}
                                            helperText={
                                                (state.amount) ? 
                                                state.amount :
                                                "How much do you intend to send?"
                                            }
                                            type="Number"
                                            onBlur={(e)=>validateMe(e, routingNo)}
                                            />
                                    </Grid>
                                </Grid>
                                

                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item xs={1}>
                                        <AccountBalanceIcon className={styles.inputIcon} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            onChange={(e) => handleInputChange(e, setBnk)}
                                            value={bnk}
                                            color="primary"
                                            margin="normal"
                                            required
                                            size="small"
                                            fullWidth
                                            id="bnk"
                                            label="Bank Name"
                                            variant="outlined"
                                            error={state.bnk !== ''}
                                            helperText={
                                                (state.bnk) ? 
                                                state.bnk :
                                                "Enter a bank Name ?"
                                            }
                                            onBlur={(e)=>validateMe(e, bnk)}
                                            />
                                    </Grid>
                                </Grid>
                                

                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item xs={1}>
                                        <FiberPinIcon className={styles.inputIcon} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            onChange={(e) => handleInputChange(e, setPin)}
                                            value={pin}
                                            color="primary"
                                            margin="normal"
                                            required
                                            size="small"
                                            fullWidth
                                            id="pin"
                                            label="Security Pin"
                                            variant="outlined"
                                            error={state.pin !== ''}
                                            helperText={
                                                (state.pin) ? 
                                                state.pin :
                                                "Enter your pin number to allow transfer"
                                            }
                                            type="Number"
                                            onBlur={(e)=>validateMe(e, pin)}
                                            />
                                    </Grid>
                                </Grid>
                                

                                 


                                

                                <Button 
                                    className={styles.Button}
                                    size="small" 
                                    onClick={() => submitForm("National")} 
                                    color="primary" 
                                    endIcon={<SendIcon />}
                                    disabled={state.error===true}
                                >
                                    Effect Transfer
                                </Button>
                            </form>
                            </AccordionDetails>
                        </Accordion>

                    </div>

                     <div className={classes.root}>
                    <Accordion 
                        expanded={expanded === 'panel2'} 
                        onChange={handleChange('panel2')}
                        style={{
                            background: state.loading ? 'rgba(1, 21, 23, .5)' : ''
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            >
                            <Typography className={classes.heading}>International Transfer</Typography>
                            <Typography className={classes.secondaryHeading}>Transfers outside the country</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                        <form noValidate className={classes.root}>
                                
                                <TextField
                                    color="primary"
                                    margin="normal"
                                    fullWidth
                                    id="accountno"
                                    select
                                    label="Debit from"
                                    value={account}
                                    onChange={(e) => handleInputChange(e,setAccount)}
                                    helperText="Please select the account you want to be debited from"
                                    >
                                        <MenuItem value="Main account - 75746873">
                                            Main account - {mainAccount}
                                        </MenuItem>
                                </TextField>
                            
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={1}>
                                    <PersonAddIcon className={styles.inputIcon} />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField
                                        onChange={(e) => handleInputChange(e, setName)}
                                        value={name}
                                        color="primary"
                                        margin="normal"
                                        required
                                        size="small"
                                        fullWidth
                                        id="name"
                                        label="Receiver's Name"
                                        variant="outlined"
                                        error={state.name !== ''}
                                        helperText={
                                            (state.name) ? 
                                            state.name :
                                            "Who are you sending money to ?"
                                        }
                                        onBlur={(e)=>validateMe(e, name)}
                                        />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={1}>
                                    <FormatListNumberedIcon className={styles.inputIcon} />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField
                                        onChange={(e) => handleInputChange(e, setAccountNo)}
                                        value={accountNo}
                                        color="primary"
                                        margin="normal"
                                        required
                                        size="small"
                                        fullWidth
                                        id="accountNo"
                                        label="Account number"
                                        variant="outlined"
                                        error={state.accountNo !== ''}
                                        helperText={
                                            (state.accountNo) ? 
                                            state.accountNo :
                                            "Receiver's account number"
                                        }
                                        type="Number"
                                        onBlur={(e)=>validateMe(e, accountNo)}
                                        />
                                </Grid>
                            </Grid>
                            

                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={1}>
                                    <FormatListNumberedIcon className={styles.inputIcon} />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField
                                        onChange={(e) => handleInputChange(e, setRoutingNo)}
                                        value={routingNo}
                                        color="primary"
                                        margin="normal"
                                        required
                                        size="small"
                                        fullWidth
                                        id="routingNo"
                                        label="Routing number"
                                        variant="outlined"
                                        error={state.routingNo !== ''}
                                        helperText={
                                            (state.routingNo) ? 
                                            state.routingNo :
                                            "Receiver's routing number"
                                        }
                                        type="Number"
                                        onBlur={(e)=>validateMe(e, routingNo)}
                                        />
                                </Grid>
                            </Grid>
                            
                            
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={1}>
                                    <AttachMoneyIcon className={styles.inputIcon} />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField
                                        onChange={(e) => handleInputChange(e, setAmount)}
                                        value={amount}
                                        color="primary"
                                        margin="normal"
                                        required
                                        size="small"
                                        fullWidth
                                        id="amount"
                                        label="Amount"
                                        variant="outlined"
                                        error={state.amount !== ''}
                                        helperText={
                                            (state.amount) ? 
                                            state.amount :
                                            "How much do you intend to send?"
                                        }
                                        type="Number"
                                        onBlur={(e)=>validateMe(e, routingNo)}
                                        />
                                </Grid>
                            </Grid>
                            

                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={1}>
                                    <AccountBalanceIcon className={styles.inputIcon} />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField
                                        onChange={(e) => handleInputChange(e, setBnk)}
                                        value={bnk}
                                        color="primary"
                                        margin="normal"
                                        required
                                        size="small"
                                        fullWidth
                                        id="bnk"
                                        label="Bank Name"
                                        variant="outlined"
                                        error={state.bnk !== ''}
                                        helperText={
                                            (state.bnk) ? 
                                            state.bnk :
                                            "Enter a bank Name ?"
                                        }
                                        onBlur={(e)=>validateMe(e, bnk)}
                                        />
                                </Grid>
                            </Grid>
                            


                            <Grid container>
                                <Grid item container xs={4}>
                                    <Grid item container spacing={1} alignItems="flex-end">
                                        <Grid item xs={1}>
                                            <MoneyIcon className={styles.inputIcon} />
                                        </Grid>
                                        <Grid item xs={11}>
                                            <TextField
                                                className={styles.smallShare}
                                                onChange={(e) => handleInputChange(e, setCurrency)}
                                                value={currency}
                                                color="primary"
                                                margin="normal"
                                                required
                                                size="small"
                                                fullWidth
                                                id="currency"
                                                label="Currency"
                                                variant="outlined"
                                                error={state.currency !== ''}
                                                helperText={
                                                    (state.currency) ? 
                                                    state.currency :
                                                    "Enter the currency"
                                                }
                                                type="text"
                                                onBlur={(e)=>validateMe(e, currency)}
                                                />
                                        </Grid>
                                    </Grid>
                                
                                </Grid>

                                <Grid item container xs={7}>
                                    <Grid item container spacing={1} alignItems="flex-end">
                                        <Grid item xs={11}>
                                            <TextField
                                                className={styles.bigShare}
                                                onChange={(e) => handleInputChange(e, setBnkAddress)}
                                                value={bnkAddress}
                                                color="primary"
                                                margin="normal"
                                                required
                                                size="small"
                                                fullWidth
                                                id="bnkAddress"
                                                label="Bank Address"
                                                variant="outlined"
                                                error={state.bnkAddress !== ''}
                                                helperText={
                                                    (state.bnkAddress) ? 
                                                    state.bnkAddress :
                                                    "Enter the receiver's bank address"
                                                }
                                                type="text"
                                                onBlur={(e)=>validateMe(e, bnkAddress)}
                                                />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <MoneyIcon className={`${styles.inputIcon} ${styles.bigShare}`} />
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                            

                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={1}>
                                    <PublicIcon className={styles.inputIcon} />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField
                                        color="primary"
                                        margin="normal"
                                        fullWidth
                                        id="country"
                                        select
                                        label="Country"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                        helperText="Select country of residence"
                                        >                                    
                                        {countryList.map((country, i) => (
                                            <MenuItem key={country.name} value={country.name}>
                                                {country.name} ({country.code})
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                </Grid>
                            </Grid>



                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={1}>
                                    <FiberPinIcon className={styles.inputIcon} />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField
                                        onChange={(e) => handleInputChange(e, setPurpose)}
                                        value={purpose}
                                        color="primary"
                                        margin="normal"
                                        required
                                        size="small"
                                        fullWidth
                                        id="pin"
                                        label="Transfer Purpose"
                                        variant="outlined"
                                        error={state.purpose !== ''}
                                        helperText={
                                            (state.purpose) ? 
                                            state.purpose :
                                            "Why are you sending money to this person ?"
                                        }
                                        type="texts"
                                        onBlur={(e)=>validateMe(e, purpose)}
                                        />
                                </Grid>
                            </Grid>
                            

                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={1}>
                                    <FiberPinIcon className={styles.inputIcon} />
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField
                                        onChange={(e) => handleInputChange(e, setPin)}
                                        value={pin}
                                        color="primary"
                                        margin="normal"
                                        required
                                        size="small"
                                        fullWidth
                                        id="pin"
                                        label="Security Pin"
                                        variant="outlined"
                                        error={state.pin !== ''}
                                        helperText={
                                            (state.pin) ? 
                                            state.pin :
                                            "Enter your pin to authorize this transfer ?"
                                        }
                                        type="Number"
                                        onBlur={(e)=>validateMe(e, pin)}
                                        />
                                </Grid>
                            </Grid>
                            


                            

                            <Button 
                                className={styles.Button}
                                size="small" 
                                onClick={() => submitForm("Interational")}
                                color="primary" 
                                endIcon={<SendIcon />}
                                disabled={state.error || state.loading}
                            >
                                Effect Transfer
                            </Button>
                        </form>
                        </AccordionDetails>
                    </Accordion>
                </div>

                { state.loading ?
                            <div className={styles.loading}>
                                <CircularProgress color="secondary" />
                            </div> : null
                        }

                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">
                                Transfer Status
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description"
                        style={{ color: state.tSuccess ? 'green' : 'red'}}>
                            {state.tSuccess ? state.tSuccess : state.tError }
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>

                    <Button style={{border: 0}} innerRef={inputRef} variant="outlined" color="primary" onClick={handleClickOpen}></Button>
                </Container>
            </Box>

        </section>
    )
}

export default Transfer;