import React from 'react';
import dgs from '../../dashGlobal.module.css';
import styles from './sideMenu.module.css';
import { NavLink } from 'react-router-dom';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { useSelector } from 'react-redux';
import { logOut } from '../../dash-comp/account/accountSlice';
import Popover from '../popover/Popover';

const icons = [
  {
    icon: <AccountBalanceIcon className={dgs.iconEl} />,
    iconText: 'Account',
    url: '/dashboard/account'
  },
  {
    icon: <LocalAtmIcon className={dgs.iconEl} />,
    iconText: 'Transfer',
    url: '/dashboard/transfer'
  },
  {
    icon: <SupervisorAccountIcon className={dgs.iconEl} />,
    iconText: 'Next of Kin',
    url: '/dashboard/kin'
  },
  {
    icon: <SettingsIcon className={dgs.iconEl} />,
    iconText: 'Profile',
    url: '/dashboard/profile'
  },
  {
    icon: <LocalActivityIcon className={dgs.iconEl} />,
    iconText: 'History',
    url: '/dashboard/history'
  },
  {
    icon: <InvertColorsIcon className={dgs.iconEl} />,
    iconText: 'Security',
    url: '/dashboard/Security'
  }

]

const SideMenu = ({toggledrawer}) => {

  const { user } = useSelector(state => state.account);
  //const dispatch = useDispatch(logOut)
  const todayDate = new Date();
  const today = ` ${todayDate.getFullYear()}/${todayDate.getMonth()}/${todayDate.getDate()}`

  return (
    <section>
      <div className={styles.menuContainer}>
        <div className={styles.profile}>
          <div>
            <img alt="profile-icon"
              src={
                user ? user.bio.image :
                "http://light.pinsupreme.com/img/avatar1.jpg"
              }
              style={{height: '50px', borderRadius: '50%'}}
              />
          </div>
          <div>
            <p> <strong> {user.bio.name} </strong> </p>
            <p> Status:
                {
                  user.status.status ? ` ${user.status.status}` : ' Inactive'
                }
            </p>
            <p>
              Last Seen:
                {
                  user.status.lastseen ? user.status.lastseen : today
                }
            </p>
          </div>
        </div>

        {icons.map((icon, index) => (
          <div key={index} className={styles.menus}>
            <NavLink onClick={toggledrawer} className={styles.navLink} activeClassName={styles.dashActive} to={icon.url}>
              <span className={styles.mIcon}> {icon.icon} </span>
              <span className={styles.mText}> {icon.iconText} </span>
            </NavLink>
          </div>
        ))}
        <div className={styles.logout}>
          <span className={styles.mIcon}> <ExitToAppIcon /> </span>
          <span className={styles.popOver}> <Popover yes={logOut} /> </span>
        </div>
      </div>
    </section>
  )
}

export default SideMenu;
