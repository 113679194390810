import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loadingIcon: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.2)'
  },
  loadingText: {
    marginLeft: '10px',
    textAlign: 'center'
  }

})


const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingIcon}>
      <CircularProgress  />
      <h4 className={classes.loadingText}>
        Fetching your<br /> data
      </h4>
    </div>
  )
}

export default Loading;
