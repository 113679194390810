import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Nomatch from '../../components/Nomatch';
import {routingData} from '../../data';
import LowerNav from '../../features/lowerNav/LowerNav';
import PremierHome from '../premier-home/PremierHome';


const Premier = () => {
    document.title="R.B.S Premier Options"

    return(
      <section>

        <LowerNav routingData={routingData[1]} />

        <Switch>
          <Route exact path="/h/premier">
              <PremierHome />
          </Route>
          {
            routingData[1].map((path, index) => (
              <Route key={index} path={path.to} component={path.component} />
            ))
          }
          <Route component={Nomatch} />
        </Switch>
      </section>
    )
}

export default Premier;
