import React from 'react';
import styles from './listComponent.module.css';
import btnStyles from '../../components/bank-accounts/bankAccounts.module.css';


const ListComponent = ({ listData }) => {
  return (
    <div className={styles.listStyles}>
      <hr className={styles.listHr} />

      <h1> {listData.heading} </h1>

      <p className={styles.pHeader}> {listData.subheader} </p>

      { listData.offer &&  <p> {listData.offer} </p> }

      { listData.listItems && 
        <ul>
          {listData.listItems.map((list, index) => (
            <li key={index}> {list} </li>
          ))}
        </ul>
      }

      <section className={styles.listInfo}>
        { listData.info && <p>
          {listData.listInfo}
        </p>
        }
        <br /><br />
      </section>

      <button className={btnStyles.viewAccountBtn}>
        <a href="/application">  </a>
      </button>
      <br /><br /><br />
    </div>
  );
};

export default ListComponent;
