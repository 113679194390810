import { createSlice } from '@reduxjs/toolkit';
import api from '../../../../features/api/axios';

const kinSlice = createSlice({
    name: 'kinRd',
    initialState: {
        error: '',
        kinName: '',
        kinNumber: '',
        kinAddress: '',
        kinCountry: '',
        kinLanguage: '',
        tSuccess: ''
    },
    reducers: {
        setError: (state, action) => {
            state.error = action.payload
        },
        doneEditing: (state, action) => {
            state.tSuccess = action.payload;
            state.error = "";
        },
    }
})

export const { doneEditing, setError } = kinSlice.actions;

export default kinSlice.reducer;

export const changeData = (data) => async (dispatch) => {
    try {
        const response = await api.put("/kin", data);
        if(response.data.success){
            dispatch(doneEditing(response.data.success));
            window.location.reload();
        } else {
            dispatch(setError(response.data.error));
        }
    } catch (err) {
        dispatch(setError("An error occured, please try again!!!"));
    }
}