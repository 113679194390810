import React from 'react';
import PageTop from '../../features/page-top/PageTop';
import { switchData } from '../../data';
import { shopOwnerImg, jointImage, serviceQualityImg } from '../../images';
import imageTextStyles from '../../features/image-text/ImageText.module.css';
import styles from './businessHome.module.css';
import ImageText from '../../features/image-text/ImageText';
import ThreeImageCard from '../../features/three-image-card/ThreeImageCard';


const BusinessImageText1 = () => {
  return (
    <section>
      <h1 style={{fontSize: '2.5em'}}> Digital Banking with you wherever you are </h1>

      <p>
        Set up your Digital Banking quickly and easily. Use it to keep track of
        your business finances 24/7, and get important account tasks done while
        you’re on the move. Eligibility criteria applies.
      </p>

      <button className={imageTextStyles.signupBtn}>
        <a href="/signup"> Register Now </a>
      </button>
    </section>
  )
}

const BusinessImageText2 = () => {
  return (
    <section>
      <h2> Our commitments to racial equality </h2>

      <p>
        We're working on how we engage with our Black, Asian and Minority Ethnic
        colleagues, customers and communities. We have set out ten commitments in
        addition to our existing plans and targets that will set the standard for
        racial equality.
      </p>

      <p>
        <a href="/our-report"> Find out more in our report </a>
      </p>
      <br />

      <p>
        <a href="/our-report-pdf"> See our commitments (PDF 38KB) </a>
      </p>
    </section>
  )
}


const BusinessImageText3 = () => {
  return (
    <section>
      <h2> Independent service quality survey results – Business current accounts </h2>

      <p>
        As part of a regulatory requirement, an independent survey was conducted
        to ask customers of the 14 largest business current account providers,
        whether they would recommend their provider to other small and medium
        enterprises (SMEs). August 2020.
      </p>
    </section>
  )
}



const BusinessHome = () => {
    return(
      <section>

        <PageTop
          header="Here for you and your business"
          buttonText="Coronavirus Help"
          buttonLink="/current-account"
          bgImage = "business"
          displayBtn = {true}
         />

       <ThreeImageCard cardData={switchData[2]} />

       <ImageText
         PageText={BusinessImageText1}
         image={jointImage}
         />

       <ThreeImageCard cardData={switchData[3]} />

       <section className={styles.shopOwner}>
         <ImageText
           PageText={BusinessImageText2}
           image={shopOwnerImg}
         />
      </section>

       <ImageText
         PageText={BusinessImageText3}
         image={serviceQualityImg}
         floatImageRight={true}
        />


      </section>
    )
}

export default BusinessHome;
