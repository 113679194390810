import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContainerMaxWidth from '../../features/cmw/ContainerMaxWidth';


const useStyles = makeStyles((theme) => ({
    root: {
        height: 'auto',
        border: '1px solid gray',
        marginBottom: '30px'
    },
    header: {
        background: '#002D64',
        padding: '.1px',
        paddingLeft: '15px',
        color: 'white',
    }
}))

const BorderContainer = ({ header, children }) => {
    const classes = useStyles();

    return (
        <ContainerMaxWidth>
            <div className={classes.root}>
                <div className={classes.header}>
                    <h4>{header}</h4>
                </div>
                <div className={classes.content}>
                    {children}
                </div>
            </div>
        </ContainerMaxWidth>
    )
}

export default BorderContainer;