import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Main from './components/main/Main';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/login/Login';
import Application from './components/application/Application';
import Nomatch from './components/Nomatch';

const App = () => <Switch>
  <Redirect exact from='/' to='/h/personal' />
  <Route path='/h' component={Main} />
  <Route path='/dashboard' component={Dashboard} />
  <Route exact path='/login' component={Login} />
  <Route exact path='/application' component={Application} />
  <Route component={Nomatch} />
</Switch>

export default App;